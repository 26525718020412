import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Card from "@mui/material/Card";
import { CardMedia } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
const useStyles = makeStyles(styles);

const ScheduleConsultationDesktop = () => {
  const classes = useStyles();
  const { t } = useTranslation("translations");
  return (
    <>
      <Box className={classes.boxConsultation}>
        <Card>
          <div style={{ position: "relative" }}>
            <CardMedia
              style={{
                height: "auto",
                width: "180%",
                maxWidth: { xs: "200%", md: "200%" },
              }}
              className={classes.imageTeeth}
              component="img"
              image={
                "https://user-images.githubusercontent.com/38444114/154776034-a2cb56c4-f9f4-452c-91a6-2d7a3d4ac20f.svg"
              }
              title="Pink tooth"
              alt="Pink tooth"
            />
            <Box className={classes.boxImageTeeth}>
              <Grid container>
                <Grid item xs={5} className={classes.textPadding}>
                  <Typography variant="h5">
                    {t("scheduleConsultation.ready")}
                  </Typography>
                  <Typography variant="h5">
                    {t("scheduleConsultation.schedule")}
                  </Typography>
                  <Typography variant="h5">
                    {t("scheduleConsultation.consultation")}
                  </Typography>
                </Grid>
                <Grid item xs={7} className={classes.buttonsPadding}>
                  <Link to={"/free_ortho_consultation_form"}>
                    <Box className={classes.consultationButton1}>
                      <Typography
                        variant={"h5"}
                        className={classes.buttonTypography}
                        padding="5px"
                      >
                        {t("heroImages.firstHomepage.buttonText")}
                      </Typography>
                    </Box>
                  </Link>

                  <div className={classes.secondButtonPadding}>
                    <Link to={"/dentistry_consultation_form"}>
                      <Box className={classes.consultationButton2}>
                        <Typography
                          variant={"h5"}
                          className={classes.buttonTypography}
                          padding="5px"
                        >
                          {t("heroImages.firstHomepage.buttonText2")}
                        </Typography>
                      </Box>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Card>
      </Box>
    </>
  );
};

export default ScheduleConsultationDesktop;
