const style = () => ({
  contentBox: {
    padding: "2% 2%",
  },
  staffName: {
    width: "100%",
    padding: "5% 0%",
    align: "center",
    textAlign: "-webkit-center",
    color: "#ff00ff",
  },
  boxImage: {
    overflow: "hidden",
    textAlign: "center",
  },
  image: {
    height: "auto",
    width: "100%",
  },

  descriptionGrid: {
    padding: "0% 5%",
  },
  title: {
    padding: "3%",
  },
  text: {
    paddingBottom: "5%",
    paddingLeft: "10%",
    paddingRight: "10%",
    lineHeight: "normal !important",
    textAlign: "justify",
  },
});
export default style;
