import { theme } from "../../styles/general";

const style = () => ({
  boxCircles: {
    backgroundColor: "#000000",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    "@media (max-width:959px)": {
      paddingBottom: "10%",
    },
  },
  gridCircles: {
    color: "#ffffff",
    padding: "0% 10%",
    textAlign: "-webkit-center",
    placeContent: "center",
    "@media (max-width:2000px)": {
      transform: "translate(0px, -30%)",
    },
    "@media (max-width:959px)": {
      transform: "translate(0%, 0%)",
      paddingTop: "3%",
    },
  },
  click: {
    "&:hover": {
      filter: "contrast(70%)",
    },
  },
  image: {
    "@media (max-width:2000px)": {
      width: "90%",
    },
    "@media (max-width:959px)": {
      width: "60%",
    },
  },
  text: {
    padding: "5%",
    fontSize: "1rem !important",
    fontFamily: "Nunito",
    fontWeight: "800",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
});
export default style;
