import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import NavBar from "./NavBar";
import SmallAppBar from "./SmallAppBar";
import { makeStyles } from "@mui/styles";
import styles from "../styles";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";

const useStyles = makeStyles(styles);

const TopBarDesktop = ({ navigation }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box className={classes.appBarImage}>
          <Link to={"/"}>
            <img
              src="https://user-images.githubusercontent.com/38444114/152898585-deb9e56b-f304-48cc-9e3e-516a8afce886.svg"
              alt="Sunshine Smiles Logo"
              width="100%"
            />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Box className={classes.appBarTextSmallBar}>
          <SmallAppBar />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <NavBar navigation={navigation} className={classes.navBar} />
      </Grid>
    </Grid>
  );
};

TopBarDesktop.propTypes = {
  title: PropTypes.string,
  handleClickMain: PropTypes.func,
  handleClickAddAlbum: PropTypes.func,
  navigation: PropTypes.any,
};

export default TopBarDesktop;
