import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Circle from "@mui/icons-material/Circle";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
const useStyles = makeStyles(styles);

const TextWithImage = ({
  title,
  text,
  text2 = [],
  image,
  alt,
  side = true,
  titleVariant,
  textVariant,
  titleColor,
  subtitle,
  subtitle2,
  subtitle3,
  subtitleVariant = "h4",
  bullet1 = [],
  bullet2 = [],
  bullet3 = [],
  bullet4 = [],
  preTitle,
  middleText,
  button = "",
  link,
}) => {
  const classes = useStyles({ titleColor });
  return (
    <Box className={classes.contentBox}>
      {side && (
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography className={classes.title} variant={titleVariant}>
              {title}
            </Typography>
            <Typography className={classes.subtitle} variant={subtitleVariant}>
              {preTitle}
            </Typography>
            {text.map(({ text }) => (
              <div key={text}>
                <Typography className={classes.text} variant={textVariant}>
                  {text}
                </Typography>
              </div>
            ))}
            {button !== "" && (
              <Box className={classes.boxButton}>
                <Link to={link}>
                  <Button className={classes.buttonPink}>{button}</Button>
                </Link>
              </Box>
            )}
            <Typography className={classes.subtitle} variant={subtitleVariant}>
              {subtitle}
            </Typography>

            <Typography className={classes.text} variant={textVariant}>
              {middleText}
            </Typography>

            {bullet2.length > 1 && (
              <Grid container spacing={2} className={classes.subtitle}>
                <Grid item xs={7}>
                  <List>
                    {bullet1.map(({ bullet1 }) => (
                      <ListItem key={bullet1}>
                        <Circle className={classes.icon} />
                        <ListItemText
                          className={classes.bullet}
                          primary={bullet1}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={5}>
                  <List>
                    {bullet2.map(({ bullet2, key }) => (
                      <ListItem key={Math.random() * Date.now() + 1}>
                        <Circle className={classes.icon} />
                        <ListItemText
                          className={classes.bullet}
                          primary={bullet2}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}

            {bullet2.length < 1 && (
              <Grid container spacing={2} className={classes.subtitle}>
                <Grid item xs={12}>
                  <List>
                    {bullet1.map(({ bullet1, key }) => (
                      <ListItem key={Math.random() * Date.now() + 1}>
                        <Circle className={classes.icon} />
                        <ListItemText
                          className={classes.bullet}
                          primary={bullet1}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}
            <Typography className={classes.subtitle} variant={subtitleVariant}>
              {subtitle2}
            </Typography>
            {text2.map(({ text2, key }) => (
              <div key={Math.random() * Date.now() + 1}>
                <Typography className={classes.text} variant={textVariant}>
                  {text2}
                </Typography>
              </div>
            ))}

            <Grid container spacing={2} className={classes.subtitle}>
              <Grid item xs={12}>
                <List>
                  {bullet3.map(({ bullet3, key }) => (
                    <ListItem key={Math.random() * Date.now() + 1}>
                      <Circle className={classes.icon} />
                      <ListItemText
                        className={classes.bullet}
                        primary={bullet3}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.boxImage}>
              <img className={classes.image} src={image} alt={alt} />
            </Box>
          </Grid>
        </Grid>
      )}
      {!side && (
        <Grid container>
          <Grid item xs={12} md={6} className={classes.image_bottom}>
            <Box className={classes.boxImage}>
              <img className={classes.image} src={image} alt={alt} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.title} variant={titleVariant}>
              {title}
            </Typography>
            <Typography className={classes.subtitle} variant={subtitleVariant}>
              {preTitle}
            </Typography>
            {text.map(({ text, key }) => (
              <div key={Math.random() * Date.now() + 1}>
                <Typography className={classes.text} variant={textVariant}>
                  {text}
                </Typography>
              </div>
            ))}
            {button !== "" && (
              <Box className={classes.boxButton}>
                <Link to={link}>
                  <Button className={classes.buttonPink}>{button}</Button>
                </Link>
              </Box>
            )}
            <Typography className={classes.subtitle} variant={subtitleVariant}>
              {subtitle}
            </Typography>

            <Typography className={classes.text} variant={textVariant}>
              {middleText}
            </Typography>

            {bullet2.length > 1 && (
              <Grid container spacing={2} className={classes.subtitle}>
                <Grid item xs={7}>
                  <List>
                    {bullet1.map(({ bullet1, key }) => (
                      <ListItem key={Math.random() * Date.now() + 1}>
                        <Circle className={classes.icon} />
                        <ListItemText
                          className={classes.bullet}
                          primary={bullet1}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={5}>
                  <List>
                    {bullet2.map(({ bullet2, key }) => (
                      <ListItem key={Math.random() * Date.now() + 1}>
                        <Circle className={classes.icon} />
                        <ListItemText
                          className={classes.bullet}
                          primary={bullet2}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}

            {bullet2.length < 1 && (
              <Grid container spacing={2} className={classes.subtitle}>
                <Grid item xs={12}>
                  <List>
                    {bullet1.map(({ bullet1, key }) => (
                      <ListItem key={Math.random() * Date.now() + 1}>
                        <Circle className={classes.icon} />
                        <ListItemText
                          className={classes.bullet}
                          primary={bullet1}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}
            <Typography className={classes.subtitle} variant={subtitleVariant}>
              {subtitle2}
            </Typography>
            {text2.map(({ text2, key }) => (
              <div key={Math.random() * Date.now() + 1}>
                <Typography className={classes.text} variant={textVariant}>
                  {text2}
                </Typography>
              </div>
            ))}

            <Grid container spacing={2} className={classes.subtitle}>
              <Grid item xs={12}>
                <List>
                  {bullet3.map(({ bullet3, key }) => (
                    <ListItem key={Math.random() * Date.now() + 1}>
                      <Circle className={classes.icon} />
                      <ListItemText
                        className={classes.bullet}
                        primary={bullet3}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>

            <Typography className={classes.subtitle} variant={subtitleVariant}>
              {subtitle3}
            </Typography>

            <Grid container spacing={2} className={classes.subtitle}>
              <Grid item xs={12}>
                <List>
                  {bullet4.map(({ bullet4, key }) => (
                    <ListItem key={Math.random() * Date.now() + 1}>
                      <Circle className={classes.icon} />
                      <ListItemText
                        className={classes.bullet}
                        primary={bullet4}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.image_bottom_2}>
            <Box className={classes.boxImage}>
              <img className={classes.image} src={image} alt={alt} />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

TextWithImage.propTypes = {
  title: PropTypes.any,
  text: PropTypes.any,
  image: PropTypes.any,
  alt: PropTypes.any,
  side: PropTypes.bool,
};

export default TextWithImage;
