import { lazy } from "react";

export const PrivacyPolicy = lazy(() =>
  import("./PrivacyPolicy/PrivacyPolicy")
);
export const TermsConditions = lazy(() =>
  import("./TermsConditions/TermsConditions")
);
export const AccessibilityStatement = lazy(() =>
  import("./AccessibilityStatement/AccessibilityStatement")
);

const accessibility_statement = "/accessibility_statement";
const privacy_policy = "/privacy_policy";
const terms_service = "/terms_service";

export const routesPolicies = [
  {
    name: "AccessibilityStatement",
    path: `${accessibility_statement}`,
    component: AccessibilityStatement,
  },
  {
    name: "PrivacyPolicy",
    path: `${privacy_policy}`,
    component: PrivacyPolicy,
  },
  {
    name: "TermsConditions",
    path: `${terms_service}`,
    component: TermsConditions,
  },
];
