import { pediatricDentistryPath } from "../../utils/globals";
import { lazy } from "react";

export const PediatricDentistry1 = lazy(() =>
  import("./PediatricDentistry1/PediatricDentistry1")
);
export const PediatricDentistry2 = lazy(() =>
  import("./PediatricDentistry2/PediatricDentistry2")
);
export const PediatricDentistry3 = lazy(() =>
  import("./PediatricDentistry3/PediatricDentistry3")
);
export const PediatricDentistry = lazy(() =>
  import("./PediatricDentistry/PediatricDentistry")
);

export const routesPediatricDentistry = [
  {
    name: "PediatricDentistry",
    path: `${pediatricDentistryPath}`,
    component: PediatricDentistry,
  },
  {
    name: "PediatricDentistry1",
    path: `${pediatricDentistryPath}/pediatric_dentistry1`,
    component: PediatricDentistry1,
  },
  {
    name: "PediatricDentistry2",
    path: `${pediatricDentistryPath}/pediatric_dentistry2`,
    component: PediatricDentistry2,
  },
  {
    name: "PediatricDentistry3",
    path: `${pediatricDentistryPath}/pediatric_dentistry3`,
    component: PediatricDentistry3,
  },
];
