import CallIcon from "@mui/icons-material/Call";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import GroupsIcon from "@mui/icons-material/Groups";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import StarRateIcon from "@mui/icons-material/StarRate";
import InfoIcon from "@mui/icons-material/Info";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import MoodIcon from "@mui/icons-material/EmojiEmotions";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

import { useTranslation } from "react-i18next";
import { orthodonticsPath } from "../globals";
import { oralSurgeryPath } from "../globals";
import { pediatricDentistryPath } from "../globals";

const useNavigation = (option) => {
  const { t } = useTranslation("translations");

  const topBar = [
    {
      name: {
        label: t("navBar.familyDentistry.familyDentistry"),
        path: "/family_dentistry",
        icon: GroupsIcon,
      },
    },
    {
      name: {
        label: t("navBar.orthodontics.orthodontics"),
        path: `/${orthodonticsPath}`,
        icon: MoodIcon,
      },
      options: [
        {
          label: t("navBar.orthodontics.free_consultation"),
          path: `/${orthodonticsPath}/free_consultation`,
        },
        {
          label: t("navBar.orthodontics.early_treatment"),
          path: `/${orthodonticsPath}/early_treatment`,
        },
        {
          label: t("navBar.orthodontics.adult_treatment"),
          path: `/${orthodonticsPath}/adult_treatment`,
        },
        {
          label: t("navBar.orthodontics.braces"),
          path: `/${orthodonticsPath}/braces`,
        },
        {
          label: t("navBar.orthodontics.clear_aligners"),
          path: `/${orthodonticsPath}/clear_aligners`,
        },
        {
          label: t("navBar.orthodontics.dental_monitoring"),
          path: `/${orthodonticsPath}/dental_monitoring`,
        },

        {
          label: t("navBar.orthodontics.retainers"),
          path: `/${orthodonticsPath}/retainers`,
        },
      ],
    },
    {
      name: {
        label: t("navBar.cosmeticRestorative.cosmeticRestorative"),
        path: "/cosmetic_restorative",
        icon: StarRateIcon,
      },
      options: [
        {
          label: t("navBar.cosmeticRestorative.dental_crowns"),
          path: "/cosmetic_restorative/dental_crowns",
        },
        {
          label: t("navBar.cosmeticRestorative.veneers"),
          path: "/cosmetic_restorative/veneers",
        },
        {
          label: t("navBar.cosmeticRestorative.dental_implants"),
          path: "/cosmetic_restorative/dental_implants",
        },
        {
          label: t("navBar.cosmeticRestorative.dentures"),
          path: "/cosmetic_restorative/dentures",
        },
        {
          label: t("navBar.cosmeticRestorative.teeth_whitening"),
          path: "/cosmetic_restorative/teeth_whitening",
        },
        {
          label: t("navBar.cosmeticRestorative.smile_makeover"),
          path: "/cosmetic_restorative/smile_makeover",
        },
        {
          label: t("navBar.cosmeticRestorative.full_mouth_reconstructions"),
          path: "/cosmetic_restorative/full_mouth_reconstructions",
        },
      ],
    },
    // {
    //   name: {
    //     label: t("navBar.pediatricDentistry.pediatricDentistry"),
    //     path: `/${pediatricDentistryPath}`,
    //     icon: ChildCareIcon,
    //   },
    //   options: [
    //     {
    //       label: t("navBar.pediatricDentistry.pediatricDentistry1"),
    //       path: `/${pediatricDentistryPath}/pediatric_dentistry1`,
    //     },
    //     {
    //       label: t("navBar.pediatricDentistry.pediatricDentistry2"),
    //       path: `/${pediatricDentistryPath}/pediatric_dentistry2`,
    //     },
    //     {
    //       label: t("navBar.pediatricDentistry.pediatricDentistry3"),
    //       path: `/${pediatricDentistryPath}/pediatric_dentistry3`,
    //     },
    //   ],
    // },
    // {
    //   name: {
    //     label: t("navBar.oralSurgery.oral_surgery"),
    //     path: `/${oralSurgeryPath}`,
    //     icon: LocalHospitalIcon,
    //   },
    //   options: [
    //     {
    //       label: t("navBar.oralSurgery.winsdom_teeth_extraction"),
    //       path: `/${oralSurgeryPath}/wisdom_teeth_extraction`,
    //     },
    //     {
    //       label: t("navBar.oralSurgery.impacted_teeth"),
    //       path: `/${oralSurgeryPath}/impacted_teeth`,
    //     },
    //     {
    //       label: t("navBar.oralSurgery.tmj_and_facial_pain"),
    //       path: `/${oralSurgeryPath}/tmj_and_facial_pain`,
    //     },
    //     {
    //       label: t("navBar.oralSurgery.biopsy"),
    //       path: `/${oralSurgeryPath}/biopsy`,
    //     },
    //   ],
    // },
    {
      name: {
        label: t("navBar.results.results"),
        path: "/results",
        icon: AutoFixHighIcon,
      },
    },
  ];

  const footerBar = [
    {
      name: {
        label: t("footerBar.about.about"),
        path: "/about",
      },
      options: [
        { label: t("footerBar.about.about"), path: "/about" },
        {
          label: t("footerBar.about.meet_doctors"),
          path: "/about/meet_doctors",
        },
        {
          label: t("footerBar.about.meet_team"),
          path: "/about/meet_team",
        },
        { label: t("footerBar.about.locations"), path: "/contact#locations" },
      ],
    },
    {
      name: {
        label: t("footerBar.services.services"),
        path: "/services",
      },
      options: [
        { label: t("footerBar.services.orthodontics"), path: "/orthodontic" },
        // {
        //   label: t("footerBar.services.pediatric_dentistry"),
        //   path: "/pediatric_dentistry",
        // },
        // {
        //   label: t("footerBar.services.oral_surgery"),
        //   path: "/oral_surgery",
        // },
        {
          label: t("footerBar.services.cosmetic"),
          path: "/cosmetic_restorative",
        },
        {
          label: t("footerBar.services.general_dentistry"),
          path: "/family_dentistry",
        },
      ],
    },
    {
      name: {
        label: t("footerBar.resources.resources"),
        path: "/resources",
      },
      options: [
        // {
        //   label: t("footerBar.resources.lastest_news"),
        //   path: "/about/blog",
        // },
        {
          label: t("footerBar.resources.insurance_finance"),
          path: "/insurance_finance",
        },
        {
          label: t("footerBar.resources.results"),
          path: "/results",
        },
        {
          label: t("footerBar.resources.consultation"),
          path: "/orthodontic/free_consultation",
        },
        {
          label: t("footerBar.resources.contact_us"),
          path: "/contact",
        },
      ],
    },
  ];

  const smallAppBar = [
    {
      name: {
        label: t("smallAppBar.about.about"),
        path: "/about",
        icon: InfoIcon,
      },
      options: [
        {
          label: t("smallAppBar.about.meet_the_doctors"),
          path: "/about/meet_doctors",
        },
        {
          label: t("smallAppBar.about.meet_the_team"),
          path: "/about/meet_team",
        },
        // { label: t("smallAppBar.about.blog"), path: "/about/blog" },
      ],
    },
    {
      name: {
        label: t("smallAppBar.insuranceFinance.insurance_finance"),
        path: "/insurance_finance",
        icon: LocalAtmIcon,
      },
      // options: [
      // {
      //   label: t(
      //     "smallAppBar.insuranceFinance.orthodontic_finance_insurance"
      //   ),
      //   path: "/insurance_finance",
      // },
      // {
      //   label: t("smallAppBar.insuranceFinance.dental_finance_insurance"),
      //   path: "/insurance_finance",
      //   hash: "#dental_insurance",
      // },
      // {
      //   label: t("smallAppBar.insuranceFinance.insurance_finance"),
      //   path: "/insurance_finance",
      // },
      // {
      //   label: t("smallAppBar.insuranceFinance.payment"),
      //   path: "/insurance_finance/payment",
      // },
      // ],
    },
    {
      name: {
        label: t("smallAppBar.forms.forms"),
        path: "/forms",
        icon: FormatAlignJustifyIcon,
      },
    },
    {
      name: {
        label: t("smallAppBar.contact.location"),
        path: "/location",
        icon: CallIcon,
      },
      // options: [
      // { label: t("smallAppBar.contact.location"), path: "/location" },
      // { label: t("smallAppBar.contact.kingston") },
      // { label: t("smallAppBar.contact.rhinebeck") },
      // { label: t("smallAppBar.contact.new_paltz") },
      // ],
    },
  ];

  const mobileMenuBar = [
    {
      name: {
        label: t("smallAppBar.about.about"),
        path: "/about",
        icon: InfoIcon,
      },
      options: [
        {
          label: t("smallAppBar.about.meet_the_doctors"),
          path: "/about/meet_doctors",
        },
        {
          label: t("smallAppBar.about.meet_the_team"),
          path: "/about/meet_team",
        },
        // { label: t("smallAppBar.about.blog"), path: "/about/blog" },
      ],
    },
    {
      name: {
        label: t("smallAppBar.insuranceFinance.insurance_finance"),
        path: "/insurance_finance",
        icon: LocalAtmIcon,
      },
      // options: [
      //   {
      //     label: t(
      //       "smallAppBar.insuranceFinance.orthodontic_finance_insurance"
      //     ),
      //     path: "/insurance_finance/orthodontic_finance_insurance",
      //   },
      //   {
      //     label: t("smallAppBar.insuranceFinance.dental_finance_insurance"),
      //     path: "/insurance_finance/dental_finance_insurance",
      //   },
      //   {
      //     label: t("smallAppBar.insuranceFinance.payment"),
      //     path: "/insurance_finance/payment",
      //   },
      // ],
    },
    {
      name: {
        label: t("smallAppBar.contact.location"),
        path: "/location",
        icon: FmdGoodIcon,
      },
    },
    {
      name: {
        label: t("smallAppBar.forms.forms"),
        path: "/forms",
        icon: FormatAlignJustifyIcon,
      },
    },
    {
      name: {
        label: t("smallAppBar.contact.contact"),
        path: "/contact",
        icon: CallIcon,
      },
    },
  ];

  if (option === "topBar") {
    return topBar;
  }
  if (option === "footerBar") {
    return footerBar;
  }
  if (option === "smallAppBar") {
    return smallAppBar;
  }
  if (option === "mobileMenu") {
    return topBar.concat(mobileMenuBar);
  }
};

export default useNavigation;
