import React, { useRef, useState } from "react";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import InputLabel from "@material-ui/core/InputLabel";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useEffect } from "react";

const useStyles = makeStyles(styles);

const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const Form = ({ page, action, form_key, accountID, type }) => {
  const classes = useStyles();
  const [isVerified, setIsVerified] = useState(true);
  const [errorTextPhone, setErrorTextPhone] = useState();
  const [phone, setPhone] = useState();
  const [errorTextEmail, setErrorTextEmail] = useState();
  const [email, setEmail] = useState();
  const { t } = useTranslation("translations");
  const recaptchaRef = useRef();

  const {
    register,
    formState: { errors },
  } = useForm();
  const onChange = () => {
    if (recaptchaRef.current.getValue()) {
      setIsVerified(false);
    } else {
      setIsVerified(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangePhone = (event) => {
    if (event.target.value.match(phoneRegex)) {
      setErrorTextPhone("");
      setPhone(event.target.value);
    } else {
      setPhone(event.target.value);
      setErrorTextPhone("Invalid US phone format");
    }
  };

  const onChangeEmail = (event) => {
    if (event.target.value.match(emailRegex)) {
      setErrorTextEmail("");
      setEmail(event.target.value);
    } else {
      setEmail(event.target.value);
      setErrorTextEmail("Invalid email format");
    }
  };
  return (
    <Box className={classes.outerBox}>
      <Box className={classes.innerBox}>
        {type === "contact" && (
          <form method="post" name="myForm" action={action}>
            <input
              {...register("accountid")}
              type="hidden"
              name="accountid"
              value={accountID}
            />
            <input type="hidden" name="key" value={form_key} />
            <Grid container className={classes.boxConsultation}>
              <Grid item xs={11} className={classes.gridRow}>
                <Typography className={classes.title} variant={"h1"}>
                  {t("form_data.contact.title1")}
                </Typography>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.contact.field1")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  type="text"
                  required
                  {...register("firstname")}
                  label="First Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.contact.field2")}
                  </Typography>
                </InputLabel>
              </Grid>
              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  type="text"
                  {...register("lastname")}
                  required
                  label="Last Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.contact.field3")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  required
                  type="text"
                  autoComplete="off"
                  placeholder="ejemplo@gmail.com"
                  label="Email"
                  error={errorTextEmail}
                  helperText={errorTextEmail}
                  value={email}
                  {...register("email")}
                  onChange={onChangeEmail}
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.contact.field4")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  required
                  type="text"
                  label="Phone"
                  error={errorTextPhone}
                  helperText={errorTextPhone}
                  value={phone}
                  {...register("phone")}
                  onChange={onChangePhone}
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.contact.field5")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  multiline
                  rows={5}
                  label="Comments"
                  {...register("comments")}
                ></TextField>
              </Grid>
              <Grid xs={4} s={1} item className={classes.gridRow}></Grid>

              <Grid item className={classes.gridRow}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  ref={recaptchaRef}
                  onChange={onChange}
                  className={classes.captcha}
                  datasize={"compact"}
                />
              </Grid>

              <Grid item xs={4} s={1} className={classes.gridRow}></Grid>

              <Grid item xs={8} s={10} className={classes.gridLastRow}>
                <input
                  type="submit"
                  value={t("form_data.contact.button")}
                  className={classes.button}
                  disabled={isVerified}
                />
              </Grid>
            </Grid>
          </form>
        )}
        {type === "freeOrthoConsultation" && (
          <form method="post" name="myForm" action={action}>
            <input type="hidden" name="accountid" value={accountID} />
            <input type="hidden" name="key" value={form_key} />
            <Grid container className={classes.boxConsultation}>
              <Grid item xs={11} className={classes.gridRow}>
                <Typography className={classes.title} variant={"h1"}>
                  {t("form_data.free_ortho_consultation.title1")}
                </Typography>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.free_ortho_consultation.field1")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  type="text"
                  required
                  {...register("firstname")}
                  label="First Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.free_ortho_consultation.field2")}
                  </Typography>
                </InputLabel>
              </Grid>
              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  type="text"
                  {...register("lastname")}
                  required
                  label="Last Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.free_ortho_consultation.field3")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  required
                  type="text"
                  autoComplete="off"
                  placeholder="ejemplo@gmail.com"
                  label="Email"
                  error={errorTextEmail}
                  helperText={errorTextEmail}
                  value={email}
                  {...register("email")}
                  onChange={onChangeEmail}
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.free_ortho_consultation.field4")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  required
                  type="text"
                  label="Phone"
                  error={errorTextPhone}
                  helperText={errorTextPhone}
                  value={phone}
                  {...register("phone")}
                  onChange={onChangePhone}
                ></TextField>
              </Grid>

              <Grid xs={4} s={1} item className={classes.gridRow}></Grid>

              <Grid item className={classes.gridRow}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  ref={recaptchaRef}
                  onChange={onChange}
                  className={classes.captcha}
                  datasize={"compact"}
                />
              </Grid>

              <Grid item xs={4} s={1} className={classes.gridRow}></Grid>

              <Grid item xs={8} s={10} className={classes.gridLastRow}>
                <input
                  type="submit"
                  value={t("form_data.free_ortho_consultation.button")}
                  className={classes.button}
                  disabled={isVerified}
                />
              </Grid>
            </Grid>
          </form>
        )}
        {type === "dentistryConsultation" && (
          <form method="post" name="myForm" class="form" action={action}>
            <input type="hidden" name="accountid" value={accountID} />
            <input type="hidden" name="key" value={form_key} />
            <Grid container className={classes.boxConsultation}>
              <Grid item xs={11} className={classes.gridRow}>
                <Typography className={classes.title} variant={"h1"}>
                  {t("form_data.dentistry_consultation.title1")}
                </Typography>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.dentistry_consultation.field1")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  type="text"
                  required
                  {...register("firstname")}
                  label="First Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.dentistry_consultation.field2")}
                  </Typography>
                </InputLabel>
              </Grid>
              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  type="text"
                  {...register("lastname")}
                  required
                  label="Last Name"
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.dentistry_consultation.field3")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  required
                  type="text"
                  autoComplete="off"
                  placeholder="ejemplo@gmail.com"
                  label="Email"
                  error={errorTextEmail}
                  helperText={errorTextEmail}
                  value={email}
                  {...register("email")}
                  onChange={onChangeEmail}
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.dentistry_consultation.field4")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  required
                  type="text"
                  label="Phone"
                  error={errorTextPhone}
                  helperText={errorTextPhone}
                  value={phone}
                  {...register("phone")}
                  onChange={onChangePhone}
                ></TextField>
              </Grid>
              <Grid item xs={4} s={1} className={classes.gridRow}>
                <InputLabel className={classes.labelName}>
                  <Typography variant={"h4"}>
                    {t("form_data.dentistry_consultation.field5")}
                  </Typography>
                </InputLabel>
              </Grid>

              <Grid item xs={8} s={10} className={classes.gridRow}>
                <TextField
                  multiline
                  rows={5}
                  label="Comments"
                  {...register("comments")}
                ></TextField>
              </Grid>
              <Grid xs={4} s={1} item className={classes.gridRow}></Grid>

              <Grid item className={classes.gridRow}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  ref={recaptchaRef}
                  onChange={onChange}
                  className={classes.captcha}
                  dataSize={"compact"}
                />
              </Grid>

              <Grid item xs={4} s={1} className={classes.gridRow}></Grid>

              <Grid item xs={8} s={10} className={classes.gridLastRow}>
                <input
                  type="submit"
                  value={t("form_data.dentistry_consultation.button")}
                  className={classes.button}
                  disabled={isVerified}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </Box>
  );
};
Form.propTypes = {
  page: PropTypes.string,
  accountID: PropTypes.string,
  form_key: PropTypes.string,
  action: PropTypes.string,
};

export default Form;
