const style = () => ({
  contentBox: {
    padding: "40px 40px",
    maxWidth: "1200px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  gridTitle: {
    padding: "5% 0",
  },
  gridTitleTypography: {
    fontSize: "2.2rem !important",
    fontFamily: "futura_maxi_cg_boldregular !important",
    "@media (max-width:1169px)": {
      fontSize: "2rem !important",
    },
    "@media (max-width:1034px)": {
      fontSize: "1.8rem !important",
    },
    "@media (max-width:746px)": {
      fontSize: "1.6rem !important",
    },
    "@media (max-width:572px)": {
      fontSize: "1.4rem !important",
    },
  },
  imgBox: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
  zoom: {
    transition: " all .2s ease-in-out",
    "&:hover": { transform: "scale(1.1)" },
  },
  typographyBox: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: "100",
    color: "#ffffff",
    flexWrap: "wrap",
    alignItems: "baseline",
    flexDirection: "column",
  },
  imgStyle: {
    width: "100%",
    height: "100%",
  },
  titleStyle: {
    fontSize: "2rem !important",
    fontFamily: "futura_maxi_cg_boldregular !important",
    "@media (max-width:1169px)": {
      fontSize: "1.4rem !important",
    },
    "@media (max-width:1034px)": {
      fontSize: "1.3rem !important",
    },
    "@media (max-width:746px)": {
      fontSize: "1.2rem !important",
    },
    "@media (max-width:572px)": {
      fontSize: "1rem !important",
    },
  },
  textStyle: {
    padding: "0% 10% 10% 10%",
    fontSize: "1.2rem !important",
    fontFamily: "Nunito !important",
    fontWeight: "800",
    "@media (max-width:1169px)": {
      fontSize: "1rem !important",
    },
    "@media (max-width:1034px)": {
      fontSize: "0.8rem !important",
    },
    "@media (max-width:769px)": {
      fontSize: "0.7rem",
    },

    "@media (max-width:294px)": {
      fontSize: "0.6rem !important",
    },
  },
});
export default style;
