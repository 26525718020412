import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styles from "../../components/HorizontalBand/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(styles);
const HorizontalBand = () => {
  const classes = useStyles();
  const { t } = useTranslation("translations");
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.mainBox}>
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div data-aos="fade-up" data-aos-duration="1000">
            <Box className={classes.boxContainer}>
              <Typography variant="pinkTitle" className={classes.normalTitle}>
                {t("horizontalBand.box1.pink")}
              </Typography>
              <Typography variant="h7" className={classes.normalTitle}>
                {t("horizontalBand.box1.text")}
              </Typography>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div data-aos="fade-up" data-aos-duration="2000">
            <Box className={classes.boxContainer}>
              <Typography variant="pinkTitle" className={classes.normalTitle}>
                {t("horizontalBand.box2.pink")}
              </Typography>
              <Typography variant="h7" className={classes.normalTitle}>
                {t("horizontalBand.box2.text")}
              </Typography>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div data-aos="fade-up" data-aos-duration="3000">
            <Box className={classes.boxContainer}>
              <Typography variant="pinkTitle" className={classes.normalTitle}>
                {t("horizontalBand.box3.pink")}
              </Typography>
              <Typography variant="h7" className={classes.normalTitle}>
                {t("horizontalBand.box3.text")}
              </Typography>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HorizontalBand;
