import { createTheme } from "@mui/material/styles";

export const themeSlogan = createTheme({
  typography: {
    fontFamily: ["Nunito"].join(","),
    fontWeight: "800",
  },
});
themeSlogan.spacing(0);

export const themeMobileTitles = createTheme({
  typography: {
    fontFamily: ["akkurat-bold-opentype_bigfontsite.com"].join(","),
  },
});
themeMobileTitles.spacing(0);

export const themeMobileSubtitles = createTheme({
  typography: {
    fontFamily: ["Nunito"].join(","),
    fontWeight: "800",
  },
});
themeMobileSubtitles.spacing(0);

export const themeCopyrightMobile = createTheme({
  typography: {
    fontFamily: ["akkurat-bold-opentype_bigfontsite.com"].join(","),
  },
});
themeCopyrightMobile.spacing(0);

const style = () => ({
  mainBox: {
    height: "calc(100vh - 40vh)",
    width: "100%",
    color: "#ffffff !important",
    backgroundColor: "#000000 !important",
    bottom: 0,
    position: "relative !important",
  },
  mainBoxMobile: {
    color: "#878787 !important",
    backgroundColor: "#000000 !important",
    height: "70%",
    maxWidth: "100%",
    paddingBottom: "10%",
    paddingTop: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  footerImageMobile: {
    padding: "10%",
    color: "#FFFFFF",
    marginTop: "2.3%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  titlesMobile: {
    paddingTop: "20px",
    paddingBottom: "20px",
    width: "100%",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  subtitlesMobile: {
    paddingBottom: "10px",
    width: "100%",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: "#fcdf4e !important",
    },
  },
  subtitles: {
    paddingBottom: "10px",
    width: "100%",
    color: "#FFFFFF",
    "&:hover": {
      color: "#fcdf4e !important",
    },
  },
  copyrightMobile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
  },
  copyrightMobileHover: {
    textAlign: "center",
    "&:hover": {
      color: "#fcdf4e !important",
    },
  },
  gridParent: {
    height: "30px",
  },
  socialMediaBox: {
    display: "flex",
    flexDirection: "row",
    width: "90%",
    justifyContent: "flex-end",
    paddingBottom: "1.5%",
    paddingTop: "1.5%",
    paddingLeft: "8%",
  },
  socialMediaBoxMobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "1.5%",
    paddingTop: "1.5%",
  },
  leftTopBoxFooter: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
    color: "#999999 !important",
  },
  rightTopHeader: {
    display: "flex",
    alignItems: "flex-end",
    height: "100%",
    flexGrow: 1,
  },
  divider: {
    background: "rgb(255, 0, 255)",
  },
  dividerV2: {
    background: "#D7D7D7",
  },
  leftBotBoxFooter: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
  },
  imgSocialMedia: {
    padding: "10px",
  },
  boxLogoImg: {
    display: "flex",
    flexDirection: "row",
    height: "85px",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  leftBotBoxFooterItem: {
    display: "flex",
    flexDirection: "column !important",
    alignItems: "flex-start",
    flexGrow: 1,
  },
  footerBar: {
    height: "70px",
    width: "100%",
    color: "#ffffff !important",
    backgroundColor: "#000000 !important",
  },
  footerBarMobile: {
    paddingTop: "20px",
    height: "170px",
    width: "100%",
    color: "#ffffff !important",
    backgroundColor: "#000000 !important",
  },
  container: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  policies: {
    display: "flex",
    flexDirection: "row",
    height: "70px",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  lastGridMobile: {
    paddingBottom: "70px",
  },
});
export default style;
