const style = () => ({
  gridContainer: {
    maxWidth: "1200px",
    width: "auto",
    paddingTop: "3.5%",
    paddingBottom: "3.5%",
  },
  boxContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  mainBox: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    backgroundColor: "#000000",
    color: "#FFFFFF",
    overflow: "hidden",
  },
});
export default style;
