import { lazy } from "react";
import { routesOrthodontics } from "./Orthodontics";
import { routesCosmeticRestorative } from "./CosmeticRestorative";
import { routesOralSurgery } from "./OralSurgery";
import { routesPediatricDentistry } from "./PediatricDentistry";
import { routesAbout } from "./About";
import { routesInsuranceFinance } from "./InsuranceFinance";
import { contact } from "./Contact";

import { routesHomepages } from "./Homepage";
import { routesPolicies } from "./Policies";

export const Homepage = lazy(() => import("./Homepage/Homepage"));
export const NotFound = lazy(() => import("./NotFound"));

export const routes = [
  {
    name: "notFound",
    path: "*",
    component: NotFound,
  },
  {
    name: "homepage",
    path: "/",
    component: Homepage,
  },
].concat(
  routesOrthodontics,
  routesCosmeticRestorative,
  routesOralSurgery,
  routesPediatricDentistry,
  routesAbout,
  routesInsuranceFinance,
  routesHomepages,
  routesPolicies,
  contact
);
