import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PropTypes from "prop-types";

import { Link, useNavigate } from "react-router-dom";

const Dropdown = ({
  name,
  options,
  navbarbutton,
  navbarbuttonv2,
  menulist,
  menuitemlist,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [buttonClass, setButtonClass] = useState(true);
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const handleToggleOpen = () => {
    setOpen(true);
  };
  const handleToggleMenuItem = () => {
    setButtonClass(false);
    handleToggleOpen();
  };

  const handleClick = (event) => {
    navigate(event.target.value ? event.target.value : "/");
  };
  const handleClickMenuItem = () => {
    handleClose();
  };
  const handleClose = () => {
    setButtonClass(true);
    setOpen(false);
  };
  const handleCloseMenuItem = () => {
    handleClose();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          color="inherit"
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onMouseOver={handleToggleOpen}
          onMouseLeave={handleClose}
          value={name.path ? name.path : "/"}
          onClick={handleClick}
          className={buttonClass ? navbarbutton : navbarbuttonv2}
        >
          {type === "navBar" && name.label}
          {type !== "navBar" && name.label}
          {options && <KeyboardArrowDownIcon />}
        </Button>
        {options && (
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                      className={menulist}
                    >
                      {options.map((option, key) => (
                        <Link
                          to={option.path ? option.path : "/"}
                          state={window.location.href}
                          key={key}
                        >
                          <MenuItem
                            className={menuitemlist}
                            onMouseOver={handleToggleMenuItem}
                            onMouseLeave={handleCloseMenuItem}
                            onClick={handleClickMenuItem}
                            data-my-value={option.path ? option.path : "/"}
                          >
                            {option.label}
                          </MenuItem>
                        </Link>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </div>
    </Stack>
  );
};
Dropdown.propTypes = {
  name: PropTypes.object,
  options: PropTypes.any,
};
export default Dropdown;
