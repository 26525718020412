import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import { Dropdown } from "../../";
import styles, { themeNavBar } from "../../Dropdown/styles";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles(styles);

const NavBar = ({ navigation, className }) => {
  const classes = useStyles();

  //
  // estilos vienen del dropdown
  //
  return (
    <ThemeProvider theme={themeNavBar}>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
        p={1}
      >
        {navigation &&
          navigation.map((option, key) => (
            <Grid item key={key} color="common.white">
              <Dropdown
                name={option.name}
                options={option.options}
                navbarbutton={classes.navBarButton}
                navbarbuttonv2={classes.navBarButtonV2}
                menulist={classes.menuList}
                menuitemlist={classes.menuItemList}
                type={"navBar"}
              />
            </Grid>
          ))}
      </Grid>
    </ThemeProvider>
  );
};

NavBar.propTypes = {
  navigation: PropTypes.any,
  className: PropTypes.any,
};

export default NavBar;
