const typography = {
  //
  //TITLES
  //
  h1: {
    fontSize: "2rem",
    fontFamily: ["futura_maxi_cg_boldregular"].join(","),
    "@media (min-width:1200px)": {
      fontSize: "3rem",
    },
    "@media (max-width:868px)": {
      fontSize: "1.5rem",
    },
    "@media (max-width:750px)": {
      fontSize: "1.4rem",
    },

    "@media (max-width:294px)": {
      fontSize: "1.5rem",
    },
  },
  h2: {
    fontSize: "2rem",
    fontFamily: ["futura_maxi_cg_boldregular"].join(","),
    "@media (max-width:1169px)": {
      fontSize: "1.75rem",
    },
    "@media (max-width:1034px)": {
      fontSize: "1.50rem",
    },
    "@media (max-width:746px)": {
      fontSize: "1.25rem",
    },
    "@media (max-width:572px)": {
      fontSize: "1.15rem",
    },
  },
  h3: {
    fontSize: "1.5rem",
    fontFamily: ["Nunito"].join(","),
    fontWeight: "800",
    "@media (max-width:1169px)": {
      fontSize: "1.4rem",
    },
    "@media (max-width:1034px)": {
      fontSize: "1.3rem",
    },
    "@media (max-width:769px)": {
      fontSize: "1.2rem",
    },

    "@media (max-width:294px)": {
      fontSize: "1rem",
    },
  },
  h4: {
    fontSize: "1.1rem",
    lineHeight: "95%",
    fontFamily: ["futura_maxi_cg_boldregular"].join(","),
    "@media (max-width:1066px)": {
      fontSize: "1rem",
    },
    "@media (max-width:572px)": {
      fontSize: "0.9rem",
    },
    "@media (max-width:480px)": {
      fontSize: "0.78rem",
    },
  },
  h5: {
    fontSize: "2rem",
    lineHeight: "95%",
    fontFamily: ["futura_maxi_cg_boldregular"].join(","),
    "@media (max-width:1066px)": {
      fontSize: "1.8rem",
    },
    "@media (max-width:987px)": {
      fontSize: "1.6rem",
    },
    "@media (max-width:800px)": {
      fontSize: "1.4rem",
    },
    "@media (max-width:720px)": {
      fontSize: "1.2rem",
    },
    "@media (max-width:572px)": {
      fontSize: "1rem",
    },
    "@media (max-width:520px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:400px)": {
      fontSize: "0.6rem",
    },
    "@media (max-width:300px)": {
      fontSize: "0.4rem",
    },
  },
  h6: {
    fontSize: "2rem",
    lineHeight: "95%",
    fontFamily: ["Nunito"].join(","),
    "@media (max-width:1066px)": {
      fontSize: "1.8rem",
    },
    "@media (max-width:987px)": {
      fontSize: "1.6rem",
    },
    "@media (max-width:800px)": {
      fontSize: "1.4rem",
    },
    "@media (max-width:720px)": {
      fontSize: "1.2rem",
    },
    "@media (max-width:572px)": {
      fontSize: "1rem",
    },
    "@media (max-width:520px)": {
      fontSize: "0.8rem",
    },
    "@media (max-width:400px)": {
      fontSize: "0.6rem",
    },
    "@media (max-width:300px)": {
      fontSize: "0.4rem",
    },
  },
  h7: {
    fontSize: "1.5em",
    fontFamily: "futura_maxi_cg_boldregular",
    textAlign: "-webkit-center",
  },
  //
  //BODY
  //
  body1: {
    fontFamily: "Nunito",
    fontWeight: "300",
    wordWrap: "break-word",
    lineHeight: "1.2",
    fontSize: "0.98em",
  },
  body2: {
    fontFamily: "Nunito",
    fontWeight: "300",
    wordWrap: "break-word",
    lineHeight: "1.8",
    fontSize: "1em",
  },
  body3: {
    fontFamily: "Nunito",
    fontWeight: "300",
    wordWrap: "break-word",
    lineHeight: "0.5",
    fontSize: "1em",
  },
  //
  //Pink typography
  //
  pink: {
    fontSize: "1.5em",
    color: "rgb(255, 0, 255) !important",
    fontFamily: "akkuratbold",
  },
  pink2: {
    fontSize: "1em",
    color: "rgb(255, 0, 255)",
    fontFamily: "Nunito",
    fontWeight: "800",
  },
  pinkTitle: {
    fontSize: "4em",
    color: "rgb(255, 0, 255)",
    fontFamily: "futura_maxi_cg_boldregular",
  },

  pinkQuote: {
    fontSize: "1.5em",
    color: "rgb(255, 0, 255)",
    fontFamily: "Nunito",
    fontWeight: "800",
    "@media (max-width:1066px)": {
      fontSize: "1.2rem",
    },
    "@media (max-width:800x)": {
      fontSize: "1rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.9rem",
    },
  },
  //
  //quote
  //
  quote: {
    fontFamily: "Nunito",
    fontWeight: "800",
    wordWrap: "break-word",
    fontSize: "1.5em",
    "@media (max-width:1066px)": {
      fontSize: "1.2rem",
    },
    "@media (max-width:800x)": {
      fontSize: "1rem",
    },
    "@media (max-width:600px)": {
      fontSize: "0.9rem",
    },
    "&::before": {
      content:
        "url(https://user-images.githubusercontent.com/76976401/156091292-5cad2034-41f9-45af-a861-aedb8da47b63.svg)",
      display: "block",
      height: "100%",
      width: "100px",
      position: "absolute",
      right: "100%",
      top: "-2px",
      "@media (max-width:1179px)": {
        content: "''",
      },
    },
  },
};

export default typography;
