import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import { Typography } from "@mui/material";
import MapContainer from "./MapContainer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);
const LocationGrid = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("translations");
  const locations = [
    {
      name: t("locationGrid.kingston.name"),
      addr1: t("locationGrid.kingston.addr1"),
      addr2: t("locationGrid.kingston.addr2"),
      tel: t("locationGrid.kingston.tel"),
      fax: t("locationGrid.kingston.fax"),
      email: t("locationGrid.kingston.email"),
      emergency: t("locationGrid.kingston.emergency"),
      button: t("locationGrid.kingston.button"),
      lat: 41.93580183953926,
      lng: -74.02534930059342,
    },
    {
      name: t("locationGrid.rhinebeck.name"),
      addr1: t("locationGrid.rhinebeck.addr1"),
      addr2: t("locationGrid.rhinebeck.addr2"),
      tel: t("locationGrid.rhinebeck.tel"),
      fax: t("locationGrid.rhinebeck.fax"),
      email: t("locationGrid.rhinebeck.email"),
      emergency: t("locationGrid.rhinebeck.emergency"),
      button: t("locationGrid.rhinebeck.button"),
      lat: 41.92853833717723,
      lng: -73.89735655092574,
    },
    {
      name: t("locationGrid.newPaltz.name"),
      addr1: t("locationGrid.newPaltz.addr1"),
      addr2: t("locationGrid.newPaltz.addr2"),
      tel: t("locationGrid.newPaltz.tel"),
      fax: t("locationGrid.newPaltz.fax"),
      email: t("locationGrid.newPaltz.email"),
      emergency: t("locationGrid.newPaltz.emergency"),
      button: t("locationGrid.newPaltz.button"),
      lat: 41.74225578777908,
      lng: -74.0713759153437,
    },
  ];
  return (
    <Box className={classes.locationGrid}>
      <Box>
        <Typography className={classes.title} variant="h2">
          <span>{t("locationGrid.general.title1")}</span>
          <span className={classes.spanClassV1}>
            {t("locationGrid.general.title2")}
          </span>
          <br />
          <span>{t("locationGrid.general.title3")}</span>
        </Typography>
      </Box>
      <Grid
        className={classes.containerGrid}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {locations.map((location, key) => (
          <Grid
            justifyContent="center"
            alignItems="center"
            key={key}
            item
            xl={4}
            lg={4}
            md={4}
            sm={12}
            xs={12}
          >
            <div data-aos="fade-up" data-aos-duration="1000">
              <Box className={classes.titleBox}>
                <Typography variant="pink" className={classes.normalTitle}>
                  {location.name}
                </Typography>
              </Box>
              <Typography variant="body1" className={classes.normalTitle}>
                {location.addr1}
                <br />
                {location.addr2}
                <br />
                <a href={"tel:" + location.tel}>
                  {t("globals.tel") + location.tel}
                </a>
                <br />
                {location.fax}
                <br />
                <a href={"mailto:" + location.email}>
                  {t("globals.email") + location.email}
                </a>
                <br />
                <a href={"tel:" + location.emergency}>
                  {t("globals.emergency") + location.emergency}
                </a>
                <br />
              </Typography>
              <Link
                to={`/contact/#${location.name.toLowerCase()}`}
                state={window.location.href}
              >
                <Box className={classes.buttonBox}>{location.button}</Box>
              </Link>

              <MapContainer lat={location.lat} lng={location.lng} />
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LocationGrid;
