import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
const useStyles = makeStyles(styles);

const AccordionMenu = ({
  title,
  image,
  alt,
  side = true,
  titleVariant,
  textVariant,
  titleColor,
  text,
}) => {
  const classes = useStyles({ titleColor });
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box className={classes.contentBox}>
      {side && (
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography className={classes.title} variant={titleVariant}>
              {title}
            </Typography>
            <div>
              {text.map(({ title, content, index }) => (
                <Accordion
                  expanded={expanded === `panel_${index}`}
                  onChange={handleChange(`panel_${index}`)}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === `panel_${index}` ? (
                        <MinimizeIcon style={{ color: "white" }} />
                      ) : (
                        <AddIcon style={{ color: "white" }} />
                      )
                    }
                    className={classes.accordionTitle}
                  >
                    <Typography variant={"body2"}>{title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionText}>
                    <Typography>{content}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.boxImage}>
              <img className={classes.image} src={image} alt={alt} />
            </Box>
          </Grid>
        </Grid>
      )}
      {!side && (
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box className={classes.boxImage}>
              <img className={classes.image} src={image} alt={alt} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography className={classes.title} variant={titleVariant}>
              {title}
            </Typography>
            {text.map(({ title, content, index }) => (
              <Accordion
                key={Math.random() * Date.now() + 1}
                expanded={expanded === `panel_${index}`}
                onChange={handleChange(`panel_${index}`)}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `panel_${index}` ? (
                      <MinimizeIcon style={{ color: "white" }} />
                    ) : (
                      <AddIcon style={{ color: "white" }} />
                    )
                  }
                  className={classes.accordionTitle}
                >
                  <Typography variant={"body2"}>{title}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionText}>
                  <Typography>{content}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

AccordionMenu.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  side: PropTypes.bool,
};

export default AccordionMenu;
