import { theme } from "../../styles/general";

const style = () => ({
  locationGrid: {
    padding: "5% 8% 0",
  },
  spanClassV1: {
    color: "rgb(255, 0, 255)",
  },
  containerGrid: { paddingTop: "5%", paddingBottom: "10%" },
  titleBox: {
    paddingBottom: "5%",
  },
  buttonBox: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    padding: "2% 2.5%",
    backgroundColor: "#ff00ff",
    color: "#ffffff",
    fontWeight: 700,
    fontSize: "16px",
    marginBottom: "5%",
    marginTop: "5%",
    border: "solid #ff00ff 2.5px",
    "&:hover": {
      color: "#ff00ff",
      backgroundColor: "#ffffff",
    },
  },
  title: {
    fontSize: "3rem !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem !important",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "2rem !important",
    },
  },
});
export default style;
