import React from "react";

import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

const useStyles = makeStyles(styles);

const SecondaryPageBannerComponent = ({ contentImage, imageAlt, text }) => {
  const classes = useStyles();
  return (
    <Box className={classes.contentImgBox}>
      <div data-aos="fade-up-right" data-aos-duration="2000">
        <Box className={classes.text}>
          {text.map(({ text, color, id }) => (
            <Box key={text + color + id}>
              <Typography
                variant={"h1"}
                color={color}
                className={classes.typography}
              >
                {text}
              </Typography>
            </Box>
          ))}
        </Box>
      </div>
      <img src={contentImage} alt={imageAlt} className={classes.img} />
    </Box>
  );
};

export default SecondaryPageBannerComponent;
