import React from "react";
import PropTypes from "prop-types";
import useNavigation from "../../utils/customHooks/useNavigation";
import FooterDesktop from "./FooterDesktop/FooterDesktop";
import FooterMobile from "./FooterMobile/FooterMobile";

const Footer = ({ isDesktop }) => {
  const footerOptions = useNavigation("footerBar");
  return (
    <div>
      {isDesktop && <FooterDesktop list_options={footerOptions} />}
      {!isDesktop && <FooterMobile list_options={footerOptions}/>}
    </div>
  );
};

Footer.propTypes = {
  isDesktop: PropTypes.bool,
};

export default Footer;
