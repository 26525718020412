import React from "react";

import styles, { themeCopyrightMobile, themeMobileSubtitles } from "../styles";
import { makeStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import { Link as LinkDOM } from "react-router-dom";
const useStyles = makeStyles(styles);

function Copyright() {
  const classes = useStyles();
  const { t } = useTranslation("translations");
  return (
    <Box className={classes.policies}>
      <ThemeProvider theme={themeCopyrightMobile}>
        <Typography>
          {t("footerBar.privacy.copyright")}
          {new Date().getFullYear()}
          {".  "}
          <Link color="inherit" href="https://sunshinesmilesus.com/">
            {t("globals.pageName")}
          </Link>
        </Typography>
      </ThemeProvider>
    </Box>
  );
}

const FooterBar = () => {
  const { t } = useTranslation("translations");
  const classes = useStyles();
  return (
    <Box className={classes.footerBar}>
      <Divider className={classes.dividerV2} />
      <Grid container>
        <Grid item xs={6}>
          <Copyright />
        </Grid>
        <Grid item xs={6}>
          <ThemeProvider theme={themeMobileSubtitles}>
            <Box className={classes.policies}>
              <LinkDOM to={"/privacy_policy"}>
                <Typography className={classes.copyrightMobileHover}>
                  {t("footerBar.privacy.privacy_policy")}
                </Typography>
              </LinkDOM>
              <LinkDOM to={"/terms_service"}>
                <Typography className={classes.copyrightMobileHover}>
                  {t("footerBar.privacy.terms_service")}
                </Typography>
              </LinkDOM>
              <LinkDOM to={"/accessibility_statement"}>
                <Typography className={classes.copyrightMobileHover}>
                  {t("footerBar.privacy.accesibility_statement")}
                </Typography>
              </LinkDOM>
            </Box>
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterBar;
