import React from "react";
import { HeroImage, BoxComponent } from "..";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import styles, { heroImageStyles } from "../AlignersImage/styles";

const useStyles = makeStyles(styles);
const AlignersImage = () => {
  const { t } = useTranslation("translations");
  const classes = useStyles();
  return (
    <>
      <HeroImage
        image={
          "https://user-images.githubusercontent.com/76976401/156096207-4dae6e3b-9c80-432d-b530-8fdd45096469.jpg"
        }
        background={
          "linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))"
        }
        BoxComponent={
          <BoxComponent
            contentImage={classes.contentImgBox}
            buttonClass={classes.consultationButton}
            buttonText={t("alignersImage.button")}
            path={"/orthodontic/clear_aligners"}
            buttonTextVariant={"h4"}
            TextComponent={() => {
              return (
                <>
                  <Typography variant="h2" className={classes.title}>
                    {t("alignersImage.discreet")}
                  </Typography>
                  <Typography variant="h3" className={classes.text}>
                    {t("alignersImage.text")}
                  </Typography>
                </>
              );
            }}
          />
        }
        styles={heroImageStyles}
      />
    </>
  );
};

export default AlignersImage;
