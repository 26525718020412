import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styles from "../styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const DescriptionGrid = ({ data }) => {
  const { t } = useTranslation("translations");
  const classes = useStyles();
  return (
    <Grid className={classes.descriptionGrid} container>
      <Grid item xs={12}>
        <Box>
          <Typography className={classes.title} variant="h3">
            {t("meet_doctors.description")}
          </Typography>
          <Typography className={classes.text} paragraph variant="body1">
            {data.description}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h3">
          {t("meet_doctors.experience")}
        </Typography>
        <Typography className={classes.text} paragraph variant="body1">
          {data.experience}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title} variant="h3">
          {t("meet_doctors.studies")}
        </Typography>
        <Typography className={classes.text} paragraph variant="body1">
          {data.studies}
        </Typography>
      </Grid>
    </Grid>
  );
};

DescriptionGrid.propTypes = {
  data: PropTypes.object,
};

export default DescriptionGrid;
