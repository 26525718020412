import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles(styles);

const ColorGrid = ({ text, titleVariant, textVariant, titleColor }) => {
  const classes = useStyles({ titleColor });
  return (
    <Box className={classes.contentBox}>
      <Grid container>
        {text.map(({ title, text, background, titleColor, textColor }) => (
          <Grid
            key={Math.random() * Date.now() + 1}
            item
            xs={12}
            md={6}
            className={classes.grid}
            style={{ backgroundColor: background }}
          >
            <Typography
              className={classes.title}
              variant={titleVariant}
              style={{ color: titleColor }}
            >
              {title}
            </Typography>
            <Typography
              className={classes.text}
              variant={textVariant}
              style={{ color: textColor }}
            >
              {text}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

ColorGrid.propTypes = {
  title: PropTypes.string,
  text: PropTypes.any,
  image: PropTypes.string,
  alt: PropTypes.string,
  side: PropTypes.bool,
};

export default ColorGrid;
