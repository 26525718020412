import { theme } from "../../styles/general";
const style = () => ({
  contentBox: {
    maxWidth: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#ffffff",

    "@media (max-width:1350px)": {
      maxWidth: "80%",
    },
    "@media (max-width:1100px)": {
      maxWidth: "90%",
    },

    [theme.breakpoints.down("md")]: {
      maxWidth: "98%",
    },
  },
  boxImage: {
    width: "100%",
    height: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  title: {
    paddingTop: "5%",
    paddingBottom: "5%",
    color: ({ titleColor }) => titleColor,
    paddingLeft: "8%",
    paddingRight: "8%",
  },
  text: {
    paddingBottom: "5%",
    paddingLeft: "10%",
    paddingRight: "10%",
    lineHeight: "normal !important",
    textAlign: "justify",
  },
  subtitle: {
    paddingBottom: "1%",
    paddingLeft: "10%",
    paddingRight: "10%",
    lineHeight: "normal !important",
    textAlign: "center",
  },
  bullet: {
    paddingRight: "0%",
    paddingLeft: "0%",
    paddingBottom: "0%",
    paddingTop: "0%",
    lineHeight: "0.5 !important",
    textAlign: "justify",
  },
  icon: {
    maxWidth: '8px',
    paddingRight: "7%",
    paddingLeft: "0%",
  },
  image_bottom: {
    "@media (max-width:800px)": {
      visibility: "hidden",
      display: "none"
    },
  },
  image_bottom_2: {
    "@media (min-width:801px)": {
      visibility: "hidden",
      display: "none"
    },
  },
  buttonPink: {
    backgroundColor: "rgb(255, 0, 255) !important",
    color: "#FFFFFF !important",
    display: "flex",
    width: "80%",
  },
  boxButton: {
    paddingLeft: "10%"
  },
});
export default style;
