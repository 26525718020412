
const style = () => ({
  contentImgBox: {
    width: "75%",
    color: "#ffffff",
    paddingLeft: "9%",
    paddingTop: "2%",
    paddingBottom: "8%",
  },
  image: {
    width: "110%",
  },
  text: {
    color: "#000000",
    position: "absolute",
    paddingTop: "30%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingLeft: "15px",
  },
  typography: {
    fontSize: "5rem !important",
    "@media (max-width:1200px)": {
      fontSize: "4rem !important",
    },
    "@media (max-width:1000px)": {
      fontSize: "3rem !important",
    },
    "@media (max-width:750px)": {
      fontSize: "2.5rem !important",
    },
    "@media (max-width:600px)": {
      fontSize: "2rem !important",
    },
    "@media (max-width:450px)": {
      fontSize: "1.5rem !important",
    },
    "@media (max-width:350px)": {
      fontSize: "1rem !important",
    },
  },
});
export default style;
