const style = () => ({
  boxBackground: {
    backgroundColor: "#000000",
    color: "#ffffff",
  },
  containerBox: {
    maxWidth: "1000px",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "6%",
  },
  itemBox: {
    width: "100%",
    height: "fit-content !important",
    minHeight: "-webkit-fill-available",
  },
  name: {
    display: "flex",
    paddingTop: "2%",
    width: "80%",
    justifyContent: "flex-end",
  },
});
export default style;
