import { createTheme } from "@mui/material/styles";

export const themeNavBar = createTheme({
  typography: {
    fontFamily: ["Nunito"].join(","),
    fontWeight: "800",
  },
});

themeNavBar.spacing(0);

const style = () => ({
  menuItemList: {
    color: "#ffffff !important",
    width: "100%",
    borderBottom: "2px solid rgb(0, 0, 0) !important",
    "&:hover": {
      borderBottom: "2px solid rgb(255, 0, 255) !important",
    },
  },
  menuList: {
    background: "#000000",
    width: "100%",
    padding: "0 !important",
  },
  navBarButton: {
    fontFamily: "Nunito !important",
    fontWeight: "800",
    fontSize: "100%",
    "&:hover": {
      borderBottom: "5px solid rgb(255, 0, 255) !important",
    },
    borderBottom: "5px solid rgb(0, 0, 0) !important",
  },
  navBarButtonV2: {
    backgroundColor: "#ffffff !important",
    color: "#000000 !important",
    borderBottom: "2px solid rgb(255, 0, 255) !important",
    fontFamily: "Nunito !important",
    fontWeight: "800",
    fontSize: "100%",
  },
});
export default style;
