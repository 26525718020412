import { lazy } from "react";
import { oralSurgeryPath } from "../../utils/globals";

export const Biopsy = lazy(() => import("./Biopsy/Biopsy"));
export const ImpactedTeeth = lazy(() =>
  import("./ImpactedTeeth/ImpactedTeeth")
);
export const TmjAndFacilPain = lazy(() =>
  import("./TmjAndFacialPain/TmjAndFacilPain")
);
export const WisdomTeethExtraction = lazy(() =>
  import("./WisdomTeethExtraction/WisdomTeethExtraction")
);
export const OralSurgery = lazy(() => import("./OralSurgery/OralSurgery"));

export const routesOralSurgery = [
  {
    name: "OralSurgery",
    path: `${oralSurgeryPath}`,
    component: OralSurgery,
  },
  {
    name: "Biopsy",
    path: `${oralSurgeryPath}/biopsy`,
    component: Biopsy,
  },
  {
    name: "ImpactedTeeth",
    path: `${oralSurgeryPath}/impacted_teeth`,
    component: ImpactedTeeth,
  },
  {
    name: "TmjAndFacilPain",
    path: `${oralSurgeryPath}/tmj_and_facial_pain`,
    component: TmjAndFacilPain,
  },
  {
    name: "WisdomTeethExtraction",
    path: `${oralSurgeryPath}/wisdom_teeth_extraction`,
    component: WisdomTeethExtraction,
  },
];
