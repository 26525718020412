import { theme } from "../../styles/general";
const style = () => ({
  contentBox: {
    maxWidth: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "1%",
    backgroundColor: "#ffffff",

    "@media (max-width:1350px)": {
      maxWidth: "80%",
    },
    "@media (max-width:1100px)": {
      maxWidth: "90%",
    },

    [theme.breakpoints.down("md")]: {
      maxWidth: "98%",
    },
  },
  title: {
    paddingTop: "5%",
    paddingBottom: "5%",
    color: ({ titleColor }) => titleColor,
    paddingLeft: "8%",
    paddingRight: "8%",
  },
  text: {
    paddingBottom: "5%",
    paddingLeft: "10%",
    paddingRight: "10%",
    lineHeight: "normal !important",
    textAlign: "justify",
  },
  grid: {
    paddingBottom: "5%",
    paddingLeft: "1%",
    paddingRight: "1%",
    lineHeight: "normal !important",
  },
});
export default style;
