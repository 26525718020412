import { createTheme } from "@mui/material/styles";
import typography from "./typography/typography";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fc28fc",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      lg: 1200,
      xl: 2036,
    },
  },
  typography: typography,
});

const style = () => ({
  fullscreen: {
    display: "flex",
    flexDirection: "column",
    background: "#ffffff",
    overflow: "hidden",
  },
  mainPadding: {
    display: "flex",
    padding: "0px",
    margin: "0px",
  },
});
export default style;
