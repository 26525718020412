import { lazy } from "react";

export const FamilyDentistry = lazy(() =>
  import("./FamilyDentistry/FamilyDentistry")
);
export const Forms = lazy(() => import("./Forms/Forms"));
export const Results = lazy(() => import("./Results/Results"));
export const FreeConsultation = lazy(() =>
  import("./FreeConsultation/FreeConsultation")
);
export const Location = lazy(() => import("./Location/Location"));
export const ThankYou = lazy(() => import("./ThankYou/ThankYou"));

const familyDentistry = "/family_dentistry";
const forms = "/forms";
const results = "/results";
const freeConsultation = "/free_consultation";
const location = "/location";
const thankYou = "/contact-us-thank-you";

export const routesHomepages = [
  {
    name: "FreeConsultation",
    path: `${freeConsultation}`,
    component: FreeConsultation,
  },
  {
    name: "FamilyDentistry",
    path: `${familyDentistry}`,
    component: FamilyDentistry,
  },
  {
    name: "Forms",
    path: `${forms}`,
    component: Forms,
  },
  {
    name: "Results",
    path: `${results}`,
    component: Results,
  },
  {
    name: "Location",
    path: `${location}`,
    component: Location,
  },
  {
    name: "ThankYou",
    path: `${thankYou}`,
    component: ThankYou,
  },
];
