import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import { Typography } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

const GridImages = ({ dataParam, gridTitle }) => {
  const data = dataParam;
  const classes = useStyles();
  return (
    <Box className={classes.contentBox}>
      <Box className={classes.gridTitle}>
        <Typography variant="h2" className={classes.gridTitleTypography}>
          {gridTitle}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {data.map((item, key) => (
          <Grid key={key} item xs={12} md={6} lg={4}>
            <Link
              to={{
                pathname: item.path,
                hash: item.hash ? item.hash : "",
              }}
              state={{ image: item.img, alt: item.alt }}
            >
              <div data-aos="fade-up" data-aos-duration="1500">
                <Box className={classes.imgBox}>
                  <Box className={classes.zoom}>
                    <Box
                      justifyContent={
                        item.justifyContent
                          ? `${item.justifyContent} !important`
                          : "flex-end"
                      }
                      className={classes.typographyBox}
                    >
                      <Typography
                        padding={
                          item.text
                            ? "10% 10% 0% 10% !important"
                            : "10% 10% 10% 10% !important"
                        }
                        variant="h2"
                        className={classes.titleStyle}
                      >
                        {item.title}
                      </Typography>
                      {item.text && (
                        <Typography variant="h3" className={classes.textStyle}>
                          {item.text}
                        </Typography>
                      )}
                    </Box>
                    <img
                      className={classes.imgStyle}
                      src={item.img}
                      alt={item.alt}
                    ></img>
                  </Box>
                </Box>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

GridImages.propTypes = {
  dataParam: PropTypes.any,
  gridTitle: PropTypes.string,
};

export default GridImages;
