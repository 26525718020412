import { lazy } from "react";

export const DentalCrowns = lazy(() => import("./DentalCrowns/DentalCrowns"));
export const DentalImplants = lazy(() =>
  import("./DentalImplants/DentalImplants")
);
export const Dentures = lazy(() => import("./Dentures/Dentures"));
export const FullMouthReconstructions = lazy(() =>
  import("./FullMouthReconstructions/FullMouthReconstructions")
);
export const SmileMakeover = lazy(() =>
  import("./SmileMakeover/SmileMakeover")
);
export const TeethWhitening = lazy(() =>
  import("./TeethWhitening/TeethWhitening")
);
export const Veneers = lazy(() => import("./Veneers/Veneers"));
export const CosmeticRestorative = lazy(() =>
  import("./CosmeticRestorative/CosmeticRestorative")
);

const cosmeticRestorativePath = "/cosmetic_restorative";
export const routesCosmeticRestorative = [
  {
    name: "CosmeticRestorative",
    path: `${cosmeticRestorativePath}`,
    component: CosmeticRestorative,
  },
  {
    name: "DentalCrowns",
    path: `${cosmeticRestorativePath}/dental_crowns`,
    component: DentalCrowns,
  },
  {
    name: "DentalImplants",
    path: `${cosmeticRestorativePath}/dental_implants`,
    component: DentalImplants,
  },
  {
    name: "Dentures",
    path: `${cosmeticRestorativePath}/dentures`,
    component: Dentures,
  },
  {
    name: "FullMouthReconstructions",
    path: `${cosmeticRestorativePath}/full_mouth_reconstructions`,
    component: FullMouthReconstructions,
  },
  {
    name: "SmileMakeover",
    path: `${cosmeticRestorativePath}/smile_makeover`,
    component: SmileMakeover,
  },
  {
    name: "TeethWhitening",
    path: `${cosmeticRestorativePath}/teeth_whitening`,
    component: TeethWhitening,
  },
  {
    name: "Veneers",
    path: `${cosmeticRestorativePath}/veneers`,
    component: Veneers,
  },
];
