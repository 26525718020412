import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";

import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { Link } from "react-router-dom";

const MenuMobile = ({ option, handleClose }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [optionState, setOptionState] = useState("");
  const handleClickMenu = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    setOptionState(option ? option : "");
  }, [option]);
  return (
    <div>
      {optionState && optionState.options && (
        <>
          <ListItemButton key={optionState.name ? optionState.name.label : ""}>
            <Link
              to={optionState.name ? optionState.name.path : "/"}
              state={window.location.href}
            >
              <ListItemIcon>
                {optionState && optionState.name && optionState.name.icon && (
                  <optionState.name.icon />
                )}
              </ListItemIcon>
            </Link>
            <Box width="100%" onClick={handleClose}>
              <Link
                to={optionState.name ? optionState.name.path : "/"}
                state={window.location.href}
              >
                <ListItemText
                  primary={optionState.name ? optionState.name.label : ""}
                />
              </Link>
            </Box>
            <Box onClick={handleClickMenu}>
              {openMenu ? <ExpandLess /> : <ExpandMore />}
            </Box>
          </ListItemButton>
          <Collapse in={openMenu} timeout="auto" unmountOnExit>
            <List component="div">
              {optionState.options &&
                optionState.options.map((item, key) => (
                  <>
                    <Link
                      to={item.path ? item.path : "/"}
                      state={window.location.href}
                    >
                      <ListItemButton
                        onClick={handleClose}
                        key={key}
                        sx={{ pl: 4 }}
                      >
                        <ListItemIcon>
                          {optionState &&
                            optionState.name &&
                            optionState.name.icon && <optionState.name.icon />}
                        </ListItemIcon>
                        <ListItemText primary={item.label} />
                      </ListItemButton>
                      <Divider />
                    </Link>
                  </>
                ))}
            </List>
          </Collapse>
        </>
      )}
      {optionState && !optionState.options && optionState.name.path && (
        <Link
          to={optionState.name.path ? optionState.name.path : "/"}
          state={window.location.href}
        >
          <ListItem
            button
            key={optionState.name ? optionState.name.label : ""}
            onClick={handleClose}
          >
            <ListItemIcon>
              {optionState && optionState.name && optionState.name.icon && (
                <optionState.name.icon />
              )}
            </ListItemIcon>
            <ListItemText
              primary={optionState.name ? optionState.name.label : ""}
            />
          </ListItem>
        </Link>
      )}
    </div>
  );
};

MenuMobile.propTypes = {
  navigation: PropTypes.any,
  handleClose: PropTypes.func,
};

export default MenuMobile;
