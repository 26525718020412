import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import styles from "./styles";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(styles);

const pinkCirclesList = [
  {
    src: "https://user-images.githubusercontent.com/38444114/155014284-7acd8632-74f4-4556-acc2-0be9744e8664.png",
    alt: "Financial",
    text: "pinkCircles.insurance",
    to: "/insurance_finance",
    time: "1000",
  },
  // {
  //   src: "https://user-images.githubusercontent.com/38444114/155014397-662977bf-3e44-4652-bbae-f704a8dfaf70.png",
  //   alt: "Community",
  //   text: "pinkCircles.safe",
  //   to: "/pediatric_dentistry",
  //   time: "1500",
  // },
  {
    src: "https://user-images.githubusercontent.com/38444114/155014467-d4db262e-2e21-4c0e-ba2e-ebc6b73cf08c.png",
    alt: "Aligners",
    text: "pinkCircles.aligners",
    to: "/orthodontic/clear_aligners",
    time: "1500",
  },
  {
    src: "https://user-images.githubusercontent.com/38444114/155014530-80310836-8b9b-4967-a9e9-6f434b1ce095.png",
    alt: "Dream Smiles",
    text: "pinkCircles.dream_smile",
    to: "/cosmetic_restorative",
    time: "2000",
  },
  // {
  //   src: "https://user-images.githubusercontent.com/38444114/155014575-932a8426-535d-49ca-9b11-eb2b2c513e33.png",
  //   alt: "Broken Tooth",
  //   text: "pinkCircles.bad_teeth",
  //   to: "/oral_surgery",
  //   time: "3000",
  // },
];

const PinkCirclesBarDesktop = () => {
  const classes = useStyles();
  const { t } = useTranslation("translations");
  return (
    <>
      <Box className={classes.boxCircles}>
        <Grid container className={classes.gridCircles}>
          {pinkCirclesList.map((value, key) => (
            <Grid
              key={key}
              item
              xl={2}
              lg={2}
              md={2}
              sm={4}
              xs={12}
              className={classes.click}
            >
              <Grid>
                <div data-aos="fade-up" data-aos-duration={value.time}>
                  <Link to={value.to}>
                    <img
                      src={value.src}
                      alt={value.alt}
                      className={classes.image}
                    />
                    <Typography variant="h2" className={classes.text}>
                      {t(value.text)}
                    </Typography>
                  </Link>
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default PinkCirclesBarDesktop;
