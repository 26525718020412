import { theme } from "../../styles/general";
const style = () => ({
  contentBox: {
    backgroundColor: "#000000",
    paddingLeft: "15%",
    paddingRight: "15%",
    paddingTop: "5%",
    minHeight: "70vh",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  boxImage: {
    width: "100%",
    height: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  title: {
    paddingTop: "5%",
    paddingBottom: "5%",
    color: ({ titleColor }) => titleColor,
    paddingLeft: "8%",
    paddingRight: "8%",
  },
  text: {
    paddingBottom: "5%",
    paddingLeft: "10%",
    paddingRight: "10%",
    lineHeight: "normal !important",
    textAlign: "justify",
  },
  subtitle: {
    paddingBottom: "0%",
    paddingLeft: "10%",
    paddingRight: "10%",
    lineHeight: "normal !important",
    textAlign: "justify",
  },
  bullet: {
    paddingRight: "0%",
    paddingLeft: "0%",
    paddingBottom: "0%",
    paddingTop: "0%",
    lineHeight: "0.5 !important",
  },
  icon: {
    maxWidth: '8px',
    paddingRight: "7%",
    paddingLeft: "0%",
  },
  accordionTitle: {
    backgroundColor: "#000000 !important",
    color: "rgb(255, 0, 255) !important",
    "&:hover": {
      backgroundColor: "#252525 !important",
    },
  },
  accordionText: {
    backgroundColor: "#000000 !important",
    color: "#ffffff"
  },
});
export default style;
