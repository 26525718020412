import React from "react";

import { HeroImage, BoxComponent } from "../";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import TextComponent from "../TextComponent/TextComponent";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import styles, { heroImageStyles } from "../../components/FamilyImage/styles";

const useStyles = makeStyles(styles);
const FamilyImage = () => {
  const { t } = useTranslation("translations");
  const classes = useStyles();
  return (
    <>
      <HeroImage
        image={
          "https://user-images.githubusercontent.com/38444114/166085421-cc29b4d5-0a20-4819-ad6f-c533cf7cbb87.png"
        }
        background={
          "linear-gradient(180deg, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.8) 100%)"
        }
        BoxComponent={
          <BoxComponent
            contentImage={classes.contentImgBox}
            buttonClass={classes.consultationButton}
            buttonText={t("familyImage.button")}
            buttonTextVariant={"h2"}
            path={"/about/meet_doctors"}
            buttonTypographyClass={classes.buttonTypography}
            TextComponent={() => {
              return (
                <>
                  <Typography align={"center"} variant="h2">
                    {t("familyImage.title")}
                  </Typography>
                </>
              );
            }}
          />
        }
        styles={heroImageStyles}
      />
      <TextComponent
        bgColor={"#000000"}
        color={"#ffffff"}
        Text={() => {
          return (
            <Box className={classes.textContainer}>
              <Box className={classes.textBox}>
                <div data-aos="zoom-out-up" data-aos-duration="1000">
                  <Typography align="center" variant="body2">
                    {t("familyImage.text1")}{" "}
                    <Typography variant="pink2">
                      {t("familyImage.text2")}
                    </Typography>{" "}
                    {t("familyImage.text3")}{" "}
                  </Typography>
                </div>
              </Box>
            </Box>
          );
        }}
      />
    </>
  );
};

export default FamilyImage;
