import { theme } from "../../styles/general";
const style = () => ({
  contentImgBox: {
    width: "37%",
    display: "flex",
    color: "#ffffff",
    position: "relative",
    bottom: "70%",
    left: "-15%",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      width: "35%",
      bottom: "45%",
      left: "30% !important",
    },
    "@media (max-width:1085px)": {
      width: "40%",
    },
    "@media (max-width:1000px)": {
      width: "40%",
      bottom: "50%",
      left: "30% !important",
    },
    "@media (max-width:959px)": {
      width: "45%",
      bottom: "43%",
      left: "27% !important",
    },
    "@media (max-width:869px)": {
      width: "40%",
      bottom: "40%",
      left: "30% !important",
    },
    "@media (max-width:750px)": {
      width: "45%",
      bottom: "42%",
      left: "27% !important",
    },
    "@media (max-width:661px)": {
      width: "50%",
      bottom: "45%",
      left: "24% !important",
    },
    "@media (max-width:595px)": {
      width: "60%",
      bottom: "42%",
      left: "20% !important",
    },
    "@media (max-width:496px)": {
      width: "70%",
      bottom: "46%",
      left: "15% !important",
    },
    "@media (max-width:425px)": {
      width: "88%",
      bottom: "48%",
      left: "7% !important",
    },
    "@media (max-width:338px)": {
      width: "90%",
      bottom: "52%",
      left: "7% !important",
    },
    "@media (max-width:295px)": {
      width: "90%",
      bottom: "60%",
      left: "7% !important",
    },
    [theme.breakpoints.up("2000px")]: {
      bottom: "35%",
    },
  },
  consultationButton1: {
    display: "flex",
    justifyContent: "center",
    padding: "1%",
    color: "rgb(255, 0, 255)",
    border: "solid #960096 2.5px",
    borderRadius: "8px",
    "&:hover": {
      color: "#FFFFFF",
      border: "solid #FF00FF 2.5px !important",
      background: "rgb(255, 0, 255)",
    },
  },

  consultationButton2: {
    display: "flex",
    justifyContent: "center",
    padding: "1%",
    //color: "#000000",
    color: "#FFFFFF",
    border: "solid #c4c2c4 2.5px",
    //background: "#FFFFFF",
    borderRadius: "8px",
    "&:hover": {
      color: "#FFFFFF",
      border: "solid #FF00FF 2.5px !important",
      background: "rgb(255, 0, 255)",
    },
  },

  secondButtonPadding: {
    paddingTop: "2%",
    paddingBottom: "5%"
  },

  firstButtonPadding: {
    paddingTop: "1%",
  },

  default: {},
  buttonTypography: {
    fontSize: "1.2rem !important",
    fontFamily: "Nunito",
    fontWeight: "800",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.3rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.5rem !important",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "0.9rem !important",
    },
  },
});
export default style;
