import React from "react";
import styles, { themeCopyrightMobile, themeMobileSubtitles } from "../styles";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import { Link as LinkDOM } from "react-router-dom";
const useStyles = makeStyles(styles);

function Copyright() {
  const classes = useStyles();
  const { t } = useTranslation("translations");
  return (
    <Box className={classes.copyrightMobile}>
      <ThemeProvider theme={themeCopyrightMobile}>
        <Typography>
          {t("footerBar.privacy.copyright")}
          {new Date().getFullYear()}
          {".  "}
          {t("globals.pageName")}
          {".  "}
        </Typography>
      </ThemeProvider>
    </Box>
  );
}

const FooterBar = () => {
  const { t } = useTranslation("translations");
  const classes = useStyles();
  return (
    <ThemeProvider theme={themeMobileSubtitles}>
      <Box className={classes.footerBarMobile}>
        <Grid rowSpacing={2} container>
          <Grid item xs={6}>
            <LinkDOM to={"/privacy_policy"}>
              <Typography className={classes.copyrightMobileHover}>
                {t("footerBar.privacy.privacy_policy")}
              </Typography>
            </LinkDOM>
          </Grid>
          <Grid item xs={6}>
            <LinkDOM to={"/terms_service"}>
              <Typography className={classes.copyrightMobileHover}>
                {t("footerBar.privacy.terms_service")}
              </Typography>
            </LinkDOM>
          </Grid>
          <Grid className={classes.lastGridMobile} item xs={12}>
            <LinkDOM to={"/accessibility_statement"}>
              <Typography className={classes.copyrightMobileHover}>
                {t("footerBar.privacy.accesibility_statement")}
              </Typography>
            </LinkDOM>
          </Grid>
        </Grid>
        <Grid item>
          <Copyright />
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default FooterBar;
