import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import { makeStyles } from "@mui/styles";

const HeroImage = ({ image, background, BoxComponent, styles, ...props }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles({ image, background });
  return (
    <Box className={classes.imageBox} {...props}>
      <div className={classes.shade} />
      {BoxComponent && BoxComponent}
    </Box>
  );
};

HeroImage.propTypes = {
  image: PropTypes.string,
  background: PropTypes.any,
};

export default HeroImage;
