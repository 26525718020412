import React from "react";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";
import Item from "./Item";

const useStyles = makeStyles(styles);
const CarouselComponent = () => {
  const classes = useStyles();
  const quotes = [
    {
      name: "Xenia Acosta",
      description:
        "Thanks to you my daughter recovered the security to smile, they are the best in what they do, a thousand thanks to the Doctor and his great work team!!!!!",
    },
    {
      name: "Saira Ramirez",
      description:
        "It’s a great place to go. I’m finally happy with my smile thanks to them. The staff is wonderful, very kind and they have both English and Spanish. Whoever needs it should consider going here.",
    },
    {
      name: "Beth Remsburger",
      description:
        "Amazing team here! I would recommend them to anyone. My daughter was very comfortable and they have multiple locations which is especially important on those emergency days. We will be back again next year for the follow up!",
    },
  ];
  return (
    <Box className={classes.boxBackground}>
      <Carousel className={classes.containerBox}>
        {quotes.map((quote, key) => (
          <div key={key}>
            <Item name={quote.name} description={quote.description} />
          </div>
        ))}
      </Carousel>
    </Box>
  );
};

export default CarouselComponent;
