import { lazy } from "react";

export const DentalFinanceInsurance = lazy(() =>
  import("./DentalFinanceInsurance/DentalFinanceInsurance")
);
export const MakePayment = lazy(() => import("./MakePayment/MakePayment"));
export const OrthodonticFinanceInsurance = lazy(() =>
  import("./OrthodonticFinanceInsurance/OrthodonticFinanceInsurance")
);
export const InsuranceFinance = lazy(() =>
  import("./InsuranceFinance/InsuranceFinance")
);

const insuranceFinancePath = "/insurance_finance";
export const routesInsuranceFinance = [
  {
    name: "InsuranceFinance",
    path: `${insuranceFinancePath}`,
    component: InsuranceFinance,
  },
  {
    name: "DentalFinanceInsurance",
    path: `${insuranceFinancePath}`,
    component: InsuranceFinance,
  },
  {
    name: "MakePayment",
    path: `${insuranceFinancePath}/payment`,
    component: MakePayment,
  },
  {
    name: "OrthodonticFinanceInsurance",
    path: `${insuranceFinancePath}`,
    component: InsuranceFinance,
  },
];
