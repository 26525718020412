import React from "react";
import PropTypes from "prop-types";
import StaffComponentDoctor from "./Staff/StaffComponentDoctor";

const StaffComponent = ({ staff, data }) => {
  return (
    <div id={data.hashLink}>
      {staff === "doctor" && <StaffComponentDoctor data={data} />}
    </div>
  );
};

StaffComponent.propTypes = {
  staff: PropTypes.string,
  data: PropTypes.object,
};

export default StaffComponent;
