const style = () => ({
  boxConsultation: {
    backgroundColor: "#ffffff !important",
    color: "#000000 !important",
    height: "100%",
  },
  imageTeeth: {
    marginTop: "-4%",
    marginLeft: "-44%",
    marginBottom: "-3%",
  },

  text: {
    padding: "5%",
  },

  buttonTypography: {
    fontSize: "1.2rem !important",
    fontFamily: "Nunito",
    fontWeight: "800",
    "@media (max-width:700px)": {
      fontSize: "0.8rem !important",
    },
    "@media (max-width:480px)": {
      fontSize: "0.6rem !important",
    },
    "@media (max-width:350px)": {
      fontSize: "0.5rem !important",
    },
  },

  contentImgBox: {
    width: "37%",
    display: "flex",
    color: "#ffffff",
    position: "relative",
    bottom: "70%",
    left: "-15%",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width:1085px)": {
      width: "40%",
    },
    "@media (max-width:1000px)": {
      width: "40%",
      bottom: "50%",
      left: "30% !important",
    },
    "@media (max-width:959px)": {
      width: "45%",
      bottom: "43%",
      left: "27% !important",
    },
    "@media (max-width:869px)": {
      width: "40%",
      bottom: "40%",
      left: "30% !important",
    },
    "@media (max-width:750px)": {
      width: "45%",
      bottom: "42%",
      left: "27% !important",
    },
    "@media (max-width:661px)": {
      width: "50%",
      bottom: "45%",
      left: "24% !important",
    },
    "@media (max-width:595px)": {
      width: "60%",
      bottom: "42%",
      left: "20% !important",
    },
    "@media (max-width:496px)": {
      width: "70%",
      bottom: "46%",
      left: "15% !important",
    },
    "@media (max-width:425px)": {
      width: "88%",
      bottom: "48%",
      left: "7% !important",
    },
    "@media (max-width:338px)": {
      width: "90%",
      bottom: "52%",
      left: "7% !important",
    },
    "@media (max-width:295px)": {
      width: "90%",
      bottom: "60%",
      left: "7% !important",
    },
  },
  consultationButton1: {
    display: "flex",
    justifyContent: "center",
    padding: "1%",
    color: "#FFFFFF",
    border: "solid #960096 2.5px",
    background: "rgb(255, 0, 255)",
    borderRadius: "8px",
    "&:hover": {
      color: "#FFFFFF",
      border: "solid #FF00FF 2.5px !important",
      background: "rgb(255, 0, 255)",
    },
  },

  consultationButton2: {
    display: "flex",
    justifyContent: "center",
    padding: "1%",
    color: "#FFFFFF",
    border: "solid #949294 2.5px",
    background: "#b4b2b4",
    //background: "#FFFFFF",
    borderRadius: "8px",
    "&:hover": {
      color: "#FFFFFF",
      border: "solid #FF00FF 2.5px !important",
      background: "rgb(255, 0, 255)",
    },
  },

  secondButtonPadding: {
    paddingTop: "2%",
    paddingBottom: "5%",
  },

  firstButtonPadding: {
    paddingTop: "1%",
  },
  textPadding: {
    paddingLeft: "10%",
    "@media (max-width:1300px)": {
      paddingLeft: "7%",
    },
    "@media (max-width:1150px)": {
      paddingLeft: "5%",
    },
    "@media (max-width:900px)": {
      paddingLeft: "3%",
    },
  },

  buttonsPadding: {
    padding: "0% 10%",
    direction: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  boxImageTeeth: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    top: "45%",
    left: "0%",
    width: "100%",
    "@media (max-width:900px)": {
      top: "30%",
    },
  },
});
export default style;
