import { theme } from "../../styles/general";
const style = () => ({
  contentImgBox: {
    width: "fit-content",
    display: "flex",
    color: "#ff00ff",
    position: "relative",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      bottom: "20%",
      left: "70%",
    },
    [theme.breakpoints.down("lg")]: {
      bottom: "20%",
      left: "70%",
    },
    [theme.breakpoints.down("md")]: {
      bottom: "20%",
      left: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "unset",
      width: "90%",
      alignItems: "flex-end",
      alignContent: "center",
      justifyContent: "center",
    },
  },
  consultationButton: {
    display: "flex",
    justifyContent: "center",
    color: "#ffffff",
    backgroundColor: "#ff00ff",
    "&:hover": {
      color: "#ff00ff",
      backgroundColor: "#ffffff",
    },
  },
  textContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  textBox: {
    display: "flex",
    width: "50%",
    justifyContent: "center",
    "@media (max-width:985px)": {
      width: "80%"
    },
    "@media (max-width:433px)": {
      width: "90%"
    },
  },
  buttonTypography: {
    fontSize: "0.9rem !important",
    fontFamily: "Nunito",
    fontWeight: "800",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem !important",
    },
  },
});

export const heroImageStyles = () => ({
  imageBox: {
    width: "100%",
    height: "80vh",
    backgroundColor: "#090502",
    [theme.breakpoints.down("lg")]: {
      height: "60vh",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      height: "40vh",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "30vh",
      width: "100%",
    },
  },
  shade: {
    height: "100%",
    width: "100%",
    backgroundImage: ({ background, image }) => `${background}, url(${image})`,
    backgroundRepeat: "no-repeat",
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundSize: "contain",
    [theme.breakpoints.up("lg")]: {
      backgroundSize: "cover",
    },
    backgroundPosition: "center center",
    indexZ: -1,
    backgroundBlendMode: "color",
  },
  default: {},
});
export default style;
