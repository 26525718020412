import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import styles from "../styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DescriptionGrid from "./DescriptionGrid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../../styles/general";

const useStyles = makeStyles(styles);

const StaffComponentDoctor = ({ data }) => {
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  useEffect(() => {
    data.side = false;
  }, [isSmall, data]);

  const classes = useStyles();
  return (
    <Box className={classes.contentBox}>
      {data.side && (
        <Grid spacing={2} container>
          <Grid item xs={12}>
            <Typography className={classes.staffName} variant="h2">
              {data.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <DescriptionGrid data={data} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.boxImage}>
              <img className={classes.image} src={data.image} alt={data.alt} />
            </Box>
          </Grid>
        </Grid>
      )}
      {!data.side && (
        <Grid spacing={2} container>
          <Grid item xs={12}>
            <Typography className={classes.staffName} variant="h2">
              {data.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.boxImage}>
              <img className={classes.image} src={data.image} alt={data.alt} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <DescriptionGrid data={data} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

StaffComponentDoctor.propTypes = {
  data: PropTypes.object,
};

export default StaffComponentDoctor;
