import React, { useState, useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Box from "@mui/material/Box";
import { routes } from "./routes";
import { theme } from "./styles/general";
import { makeStyles } from "@mui/styles";
import styles from "./styles/general";
import AOS from "aos";
import "aos/dist/aos.css";

import useMediaQuery from "@mui/material/useMediaQuery";

import { TopBar, Footer, ScrollTop, LoadingComponent } from "./components";

import { ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles(styles);

function App() {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width: 1455px)", {
    defaultMatches: true,
  });
  const [topBarHeight, setTopBarHeight] = useState(0);
  const isDesktopFooter = useMediaQuery("(min-width: 900px)", {
    defaultMatches: true,
  });
  useEffect(() => {
    AOS.init();
  });

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.fullscreen}>
        <>
          <TopBar isDesktop={isDesktop} setTopBarHeight={setTopBarHeight} />
          <Suspense fallback={<LoadingComponent />}>
            <Box style={{ marginTop: `${topBarHeight}px` }}>
              <Routes>
                {routes.map(({ path, component: Component, name }) => (
                  <Route key={name} path={path} element={<Component />} />
                ))}
              </Routes>
            </Box>
          </Suspense>
          <Footer isDesktop={isDesktopFooter} />
        </>
      </Box>
      <ScrollTop />
    </ThemeProvider>
  );
}

export default App;
