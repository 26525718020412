const style = () => ({
  contentImgBox: {
    width: "100%",
    color: "#ffffff",
    "@media (max-width:494px)": {
      height: "40vh",
    },
  },
  image: {
    width: "100%",
    "@media (max-width:494px)": {
      height: "40vh",
    },
  },
  title: {
    color: "#000000",
    position: "absolute",
    paddingTop: "10%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingLeft: "35px",
    "@media (max-width:750px)": {
      paddingTop: "5%",
      flexDirection: "row",
      justifyContent: "left",
    },
    "@media (max-width:320px)": {
      paddingTop: "10%",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  text: {
    color: "#000000",
    position: "absolute",
    paddingTop: "8%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "30%",
    height: "60vh",
    paddingLeft: "35px",
    textAlign: "justify",
    "@media (max-width:1000px)": {
      height: "45vh",
      width: "35%",
    },
    "@media (max-width:750px)": {
      height: "30vh",
      width: "45%",
    },
    "@media (max-width:601px)": {
      height: "25vh",
      width: "45%",
    },
    "@media (max-width:550px)": {
      height: "28vh",
      width: "47%",
    },
    "@media (max-width:447px)": {
      height: "25vh",
      width: "45%",
    },
    "@media (max-width:385px)": {
      height: "32vh",
      width: "45%",
    },
    "@media (max-width:320px)": {
      height: "30vh",
      width: "43%",
    },
  },
  typography: {
    lineHeight: "2 !important",
    "@media (max-width:1000px)": {
      fontSize: "0.9rem !important",
      lineHeight: "1.5 !important",
    },
    "@media (max-width:601px)": {
      fontSize: "0.8rem !important",
    },
    "@media (max-width:507px)": {
      lineHeight: "1.2 !important",
    },
    "@media (max-width:447px)": {
      fontSize: "0.7rem !important",
      lineHeight: "1.2 !important",
    },
    "@media (max-width:320px)": {
      fontSize: "0.6rem !important",
      lineHeight: "1 !important",
    },
  },
});
export default style;
