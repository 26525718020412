import { orthodonticsPath } from "../../utils/globals";
import { lazy } from "react";

export const AdultTreatment = lazy(() =>
  import("./AdultTreatment/AdultTreatment")
);
export const Braces = lazy(() => import("./Braces/Braces"));
export const ClearAligners = lazy(() =>
  import("./ClearAligners/ClearAligners")
);
export const DentalMonitoring = lazy(() =>
  import("./DentalMonitoring/DentalMonitoring")
);
export const EarlyTreatment = lazy(() =>
  import("./EarlyTreatment/EarlyTreatment")
);
export const FreeConsultation = lazy(() =>
  import("./FreeConsultation/FreeConsultation")
);
export const Retainers = lazy(() => import("./Retainers/Retainers"));
export const Orthodontics = lazy(() => import("./Orthodontics/Orthodontics"));

export const routesOrthodontics = [
  {
    name: "Orthodontics",
    path: `${orthodonticsPath}`,
    component: Orthodontics,
  },
  {
    name: "AdultTreatment",
    path: `${orthodonticsPath}/adult_treatment`,
    component: AdultTreatment,
  },
  {
    name: "Braces",
    path: `${orthodonticsPath}/braces`,
    component: Braces,
  },
  {
    name: "ClearAligners",
    path: `${orthodonticsPath}/clear_aligners`,
    component: ClearAligners,
  },
  {
    name: "DentalMonitoring",
    path: `${orthodonticsPath}/dental_monitoring`,
    component: DentalMonitoring,
  },
  {
    name: "EarlyTreatment",
    path: `${orthodonticsPath}/early_treatment`,
    component: EarlyTreatment,
  },
  {
    name: "FreeConsultation",
    path: `${orthodonticsPath}/free_consultation`,
    component: FreeConsultation,
  },
  {
    name: "Retainers",
    path: `${orthodonticsPath}/retainers`,
    component: Retainers,
  },
];
