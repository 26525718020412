import React from "react";
import PropTypes from "prop-types";
import styles, {
  themeSlogan,
  themeMobileTitles,
  themeMobileSubtitles,
} from "../styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FooterBarMobile from "./FooterBarMobile";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

const FooterMobile = ({ list_options }) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={themeSlogan}>
      <Box className={classes.mainBoxMobile}>
        <Box className={classes.socialMediaBoxMobile}>
          <a
            href="https://www.facebook.com/SunshineSmilesUS"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon className={classes.imgSocialMedia} />
          </a>
          <a
            href="https://www.instagram.com/sunshinesmilesus/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon className={classes.imgSocialMedia} />
          </a>
        </Box>
        <Box className={classes.footerImageMobile}>
          <img
            src="https://user-images.githubusercontent.com/38444114/152898585-deb9e56b-f304-48cc-9e3e-516a8afce886.svg"
            alt="Sunshine Smiles Logo"
            width="100%"
          />
        </Box>

        <Box className={classes.footerImageMobile}>
          {list_options.map((option, key) => (
            <Grid>
              <Grid container>
                <Grid items key={key}>
                  <Box className={classes.titlesMobile}>
                    <ThemeProvider theme={themeMobileTitles}>
                      <Typography>{option.name.label}</Typography>
                    </ThemeProvider>
                  </Box>
                  {option.options.map((item, key) => (
                    <Link
                      to={item.path ? item.path : "/"}
                      state={window.location.href}
                    >
                      <Box key={key} className={classes.subtitlesMobile}>
                        <ThemeProvider theme={themeMobileSubtitles}>
                          <Typography>{item.label}</Typography>
                        </ThemeProvider>
                      </Box>
                    </Link>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Box>

        <FooterBarMobile />
      </Box>
    </ThemeProvider>
  );
};

FooterMobile.propTypes = {
  list_options: PropTypes.any,
};

export default FooterMobile;
