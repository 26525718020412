// import { theme } from "../../styles/general";
const style = () => ({
  contentImgBox: {
    width: "100%",
    display: "flex",
    color: "#ffffff",
    position: "relative",
    height: "60vh",
    paddingBottom: "8%",
    flexDirection: "column",
    "@media (max-width:1850px)": {
      height: '60vh',
    },
    "@media (max-width:1500px)": {
      height: '60vh',
    },
    "@media (max-width:1000px)": {
      height: '40vh',
    },
    "@media (max-width:850px)": {
      height: '35vh',
    },
    "@media (max-width:750px)": {
      height: '30vh',    
    },
    "@media (max-width:600px)": {
      height: '25vh',    
    },
    "@media (max-width:450px)": {
      height: '20vh',    
    },
    "@media (max-width:410px)": {
      height: '20vh',    
    },
    "@media (max-width:300px)": {
      height: '13vh',    
    },
  },
  text: {
    color: "#000000",
    position: "absolute",
    paddingTop: "15%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    left: "3%",
    width: "100%",
    height: "100%",
    paddingLeft: "15px",
  },
  typography: {
    fontSize: "5rem !important",
    "@media (max-width:1000px)": {
      fontSize: "4rem !important",
    },
    "@media (max-width:750px)": {
      fontSize: "3rem !important",
    },
    "@media (max-width:600px)": {
      fontSize: "2.5rem !important",
    },
    "@media (max-width:450px)": {
      fontSize: "2rem !important",
    },
    "@media (max-width:410px)": {
      fontSize: "1.5rem !important", 
    },
    "@media (max-width:300px)": {
      fontSize: "1rem !important", 
    },
  },
  img: {
    height: '110%',
    width: '100%',
    objectFit: 'cover',
  },
});
export default style;
