import { createTheme } from "@mui/material/styles";

export const themeNavBar = createTheme({
  typography: {
    fontFamily: ["Nunito"].join(","),
    fontWeight: "800",
  },
});

themeNavBar.spacing(0);

export const themeSmallBar = createTheme({
  typography: {
    fontFamily: ["Nunito"].join(","),
    fontWeight: "800",
  },
});

export const themeMobile = createTheme({
  typography: {
    fontFamily: ["akkurat-bold-opentype_bigfontsite.com"].join(","),
  },
});

themeSmallBar.spacing(6);

const style = () => ({
  appBar: {
    width: "100%",
    paddingLeft: "4% !important",
    paddingRight: "3% !important",
    top: "0px",
    position: "fixed !important",
    background: "#000000 !important",
  },
  appBarMobile: {
    width: "100%",
    paddingLeft: "1% !important",
    paddingRight: "1% !important",
    paddingTop: "0.3% !important",
    position: "fixed !important",
    background: "#FFFFFF !important",
  },
  navBar: {
    background: "#000000",
    width: "100%",
    zIndex: 0,
  },
  navBarMobile: {
    background: "#FFFFFF",
    width: "100%",
    zIndex: 0,
  },
  appBarImage: {
    padding: "4% 0%",
    width: "100%",
    color: "#FFFFFF",
    marginTop: "2.3%",
  },
  appBarImageMobile: {
    paddingTop: "3.5px",
    width: "119px",
    marginTop: "15px",
    marginLeft: "20px",
    paddingBottom: "17px",
    "@media (max-width:475px)": {
      marginLeft: "0px",
    },
  },
  menuMobile: {
    width: "100%",
    marginTop: "0%",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    color: "#000000",
  },
  labelMenuMobile: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "40px",
    paddingTop: "3px",
    fontSize: "10px",
    alignItems: "center",
    "@media (max-width:475px)": {
      paddingRight: "0px",
    },
  },
  appBarContact: {
    width: "100%",
    height: "-500%",
    marginLeft: "30%",
  },
  appBarTextSmallBar: {
    marginTop: "0.3%",
    marginLeft: "11.8%",
    paddingBottom: "0%",
    width: "80%",
    fontSize: "85%",
    fontFamily: ["Nunito", "sans-serif"].join(","),
    fontWeight: "800",
    borderBottom: "1px solid rgb(255, 0, 255)",
    zIndex: 1,
    position: "relative",
  },
  appBarMarginBorderSmall: {
    marginBottom: "40%",
    //paddingLeft: "2.5%",
    borderRadius: "0px",
  },
  phoneIcon: {
    marginTop: "40%",
    //marginLeft: "170%",
    marginBottom: "10%",
  },
  downNavBar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  smallBarMenu: {
    marginTop: "1%",
    marginLeft: "11.8%",
    paddingBottom: "1%",
    width: "81%",
  },
  menuItemList: {
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#474747 !important",
    },
    borderBottom: "1.5px solid rgb(255, 0, 255) !important",
  },
  menuList: {
    background: "#303030",
  },
  navBarButton: {
    "&:hover": {
      backgroundColor: "#303030 !important",
    },
  },
  buttonPink: {
    backgroundColor: "rgb(255, 0, 255) !important",
    color: "#FFFFFF !important",
    display: "flex",
    width: "100%",
  },
  navBarButtonPink: {
    background: "#ff00ff !important",
  },

  mobilePhone: {
    "@media (max-width:1455px)": {
      paddingTop: "3%",
      paddingLeft: "77%",
    },
    "@media (max-width:1396px)": {
      paddingTop: "3%",
      paddingLeft: "73%",
    },
    "@media (max-width:1200px)": {
      paddingTop: "4%",
      paddingLeft: "65%",
    },
    "@media (max-width:1000px)": {
      paddingTop: "5%",
      paddingLeft: "55%",
    },
    "@media (max-width:840px)": {
      paddingTop: "6%",
      paddingLeft: "45%",
    },
    "@media (max-width:740px)": {
      paddingTop: "7%",
      paddingLeft: "35%",
    },
    "@media (max-width:660px)": {
      paddingTop: "7%",
      paddingLeft: "25%",
    },
    "@media (max-width:580px)": {
      paddingTop: "8%",
      paddingLeft: "10%",
    },
    "@media (max-width:510px)": {
      paddingTop: "10%",
      paddingLeft: "0%",
    },
    "@media (max-width:420px)": {
      paddingTop: "13%",
      paddingLeft: "0%",
    },
    "@media (max-width:380px)": {
      paddingTop: "16%",
      paddingLeft: "0%",
    },
    "@media (max-width:300px)": {
      paddingTop: "20%",
      paddingLeft: "0%",
    },
  },

  mobilePhoneTextColor: {
    color: "#303030 !important",
    background: "rgb(255, 0, 255) !important",
    borderRadius: "8px",
    "&:hover": {
      color: "rgb(255, 0, 255)!important",
      background: "rgb(0, 0, 0) !important",
    },
  },

  mobilePhoneTextContact: {
    color: "#303030 !important",
    fontFamily: ["Nunito", "sans-serif"].join(","),
  },

  phoneTextSize: {
    "@media (max-width:380px)": {
      fontSize: "80%",
    },
    "@media (max-width:340px)": {
      fontSize: "70%",
    },
    "@media (max-width:320px)": {
      fontSize: "60%",
    },
    "@media (max-width:295px)": {
      fontSize: "50%",
    },
  },
});
export default style;
