import { lazy } from "react";

export const Blog = lazy(() => import("./Blog/Blog"));
export const MeetDoctor = lazy(() => import("./MeetDoctor/MeetDoctor"));
export const MeetTeam = lazy(() => import("./MeetTeam/MeetTeam"));
export const About = lazy(() => import("./About/About"));

const aboutPath = "/about";

export const routesAbout = [
  {
    name: "Blog",
    path: `${aboutPath}/blog`,
    component: Blog,
  },
  {
    name: "MeetDoctor",
    path: `${aboutPath}/meet_doctors`,
    component: MeetDoctor,
  },
  {
    name: "MeetTeam",
    path: `${aboutPath}/meet_team`,
    component: MeetTeam,
  },
  {
    name: "About",
    path: `${aboutPath}`,
    component: About,
  },
];
