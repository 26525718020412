import React from "react";

import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(styles);

const BoxComponent = ({
  buttonClass,
  buttonText,
  buttonText2,
  contentImage,
  TextComponent,
  path,
  path2,
  buttonTextVariant,
  buttonTypographyClass,
}) => {
  const classes = useStyles();
  return (
    <Box className={contentImage ? contentImage : classes.contentImgBox}>
      <div data-aos="flip-up" data-aos-duration="2000">
        <TextComponent />
        {buttonText && (
          <div className={classes.firstButtonPadding}>
            <Link to={path ? path : "/"}>
              <Box
                className={
                  buttonClass ? buttonClass : classes.consultationButton1
                }
              >
                <Typography
                  variant={buttonTextVariant ? buttonTextVariant : "h2"}
                  className={
                    buttonTypographyClass
                      ? buttonTypographyClass
                      : classes.default
                  }
                  padding="5px"
                >
                  {/* {buttonText.toUpperCase()} */}
                  {buttonText}
                </Typography>
              </Box>
            </Link>
          </div>
        )}
        {buttonText2 && (
          <div className={classes.secondButtonPadding}>
            <Link to={path2 ? path2 : "/"}>
              <Box className={classes.consultationButton2}>
                <Typography
                  variant={buttonTextVariant ? buttonTextVariant : "h2"}
                  className={
                    buttonTypographyClass
                      ? buttonTypographyClass
                      : classes.default
                  }
                  padding="5px"
                >
                  {/* {buttonText2.toUpperCase()} */}
                  {buttonText2}
                </Typography>
              </Box>
            </Link>
          </div>
        )}
      </div>
    </Box>
  );
};

export default BoxComponent;
