import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import styles, { themeMobile } from "../styles";
import Grid from "@mui/material/Grid";
import Logo from "../../../images/logo_mobile.png";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import { ThemeProvider } from "@mui/material/styles";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTranslation } from "react-i18next";

import MenuMobile from "./MenuMobile";

const useStyles = makeStyles(styles);

const TopBarMobile = ({ navigation }) => {
  const { t } = useTranslation("translations");
  const classes = useStyles();
  const [state, setState] = useState({
    right: false,
  });
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, ["right"]: open });
  };

  useEffect(() => {
    setIsOpen(state["right"]);
  }, [state]);

  const list = (anchor) => (
    <ThemeProvider theme={themeMobile}>
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
        role="presentation"
      >
        <List>
          <ListItem button key={"Sunshine Smiles"}>
            <ListItemIcon>
              <AutoAwesomeIcon />
            </ListItemIcon>
            <Link to={"/"}>
              <ListItemText
                primary={"Sunshine Smiles"}
                onClick={toggleDrawer("right", false)}
              />
            </Link>
          </ListItem>
          <Divider />
          {navigation.map((option, key) => (
            <>
              <MenuMobile
                key={key}
                option={option}
                handleClose={toggleDrawer("right", false)}
              />
              <Divider />
            </>
          ))}
        </List>
      </Box>
    </ThemeProvider>
  );

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box className={classes.appBarImageMobile}>
          <Link to={"/"}>
            <img src={Logo} alt="Sunshine Smiles Logo" width="100%" />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={5}>
        <div className ={classes.mobilePhone}>
          <a href={"tel:" + t("smallAppBar.number.number")}>
            <Button
              id="cellphone-button"
              aria-haspopup="true"
              className = {classes.mobilePhoneTextColor}
            >
              <LocalPhoneIcon style={{ fontSize: 15 }}/>
              <div className = {classes.phoneTextSize}>
              {t("smallAppBar.number.number")}
              </div>
            </Button>
          </a>
        </div>
      </Grid>
      <Grid item xs={1}>
        <Box className={classes.menuMobile}>
          <div>
            <IconButton
              aria-label="menu icon"
              style={{ backgroundColor: "transparent" }}
              onClick={toggleDrawer("right", true)}
            >
              <Box className={classes.labelMenuMobile}>
                <MenuIcon />
                {t("globals.menuTitle").toUpperCase()}
              </Box>
            </IconButton>
            <Drawer
              anchor={"right"}
              open={isOpen}
              onClose={toggleDrawer("right", false)}
              onOpen={toggleDrawer("right", true)}
            >
              {list("right")}
            </Drawer>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

TopBarMobile.propTypes = {
  title: PropTypes.string,
  handleClickMain: PropTypes.func,
  handleClickAddAlbum: PropTypes.func,
  navigation: PropTypes.any,
};

export default TopBarMobile;
