import React from "react";
import { makeStyles } from "@mui/styles";
import styles, { themeSmallBar } from "../styles";
import Grid from "@mui/material/Grid";
import { Dropdown } from "../..";
import { ThemeProvider } from "@mui/material/styles";
import useNavigation from "../../../utils/customHooks/useNavigation";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const SmallAppBar = () => {
  const classes = useStyles();
  const { t } = useTranslation("translations");
  const navigation = useNavigation("smallAppBar");
  return (
    <ThemeProvider theme={themeSmallBar}>
      <Grid
        container
        padding="0"
        alignItems="stretch"
        justifyContent="space-evenly"
        spacing={0.5}
      >
        <Grid item>
          <div>
            <a href={"tel:" + t("smallAppBar.number.number")}>
              <Button
                color="inherit"
                id="cellphone-button"
                aria-haspopup="true"
              >
                <LocalPhoneIcon />
                {t("smallAppBar.number.number")}
              </Button>
            </a>
          </div>
        </Grid>
        {navigation &&
          navigation.map((option, key) => (
            <Grid item key={key}>
              <Dropdown
                name={option.name}
                options={option.options}
                navbarbutton={classes.navBarButton}
                navbarbuttonv2={classes.navBarButtonV2}
                menulist={classes.menuList}
                menuitemlist={classes.menuItemList}
                index={key}
              />
            </Grid>
          ))}
        <Grid item key={"get started"}>
          <Link to="/contact">
            <Button className={classes.buttonPink}>
              {t("smallAppBar.contact.contact")}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default SmallAppBar;
