import { theme } from "../../styles/general";
const style = () => ({
  scrollBar: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    zIndex: 100,
  },
});
export default style;
