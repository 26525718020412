import React from "react";

import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(styles);

const MainPageBannerComponent = ({ contentImage, imageAlt, text }) => {
  const classes = useStyles();
  return (
    <Box className={classes.contentImgBox}>
      <div data-aos="fade-up-right" data-aos-duration="2000">
        <Box className={classes.text}>
          {text.map(({ text, color, key }) => (
            <Typography
              variant={"h1"}
              color={color}
              className={classes.typography}
              key={Math.random() * Date.now() + 1}
            >
              {text}
            </Typography>
          ))}
        </Box>
      </div>
      <img className={classes.image} src={contentImage} alt={imageAlt} />
    </Box>
  );
};

export default MainPageBannerComponent;
