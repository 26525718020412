import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import styles from "../../components/TextComponent/styles";

const useStyles = makeStyles(styles);

const TextComponent = ({ Text, Title, bgColor, color }) => {
  const classes = useStyles();
  return (
    <Box backgroundColor={bgColor} color={color}>
      <Box className={classes.contentBox}>
        {Title && <Title />}
        {Text && <Text />}
      </Box>
    </Box>
  );
};

TextComponent.propTypes = {
  Text: PropTypes.any,
  Title: PropTypes.any,
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

export default TextComponent;
