const style = () => ({
    boxConsultation: {
      backgroundColor: "#f0f1f4 !important",
      color: "#000000 !important",
      paddingTop: "2%",
      paddingLeft: "8%",
      paddingRight: "8%",
    },
    title: {
      color: "#fc28fc",
    },
    labelName: {
      color: "#123456",
      paddingTop: "5%",    
    },
    gridRow: {
      paddingTop: "1%",
    },
    gridLastRow: {
      paddingTop: "1%",
      paddingBottom: "3%",
    },
    captcha: {
      transform:"scale(0.85)",
      transformOrigin:"0 0"
    },
    innerBox: {
      padding: "1.5%",
      width: "80%",
      backgroundColor: "#fc28fc",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      "@media (max-width:420px)": {
        padding: "0%",
      },
    },
    outerBox: {
      paddingBottom: "5%",
      paddingTop: "5%",
      width: "90%",
      //backgroundColor: "#666666",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      backgroundImage:`url(${"https://user-images.githubusercontent.com/38444114/164793663-cd2bd7f0-687b-4d24-9ea0-e363d1ec54a6.png"})`,
      //backgroundSize: 'cover',
      backgroundPosition: 'center',
      "@media (max-width:420px)": {
        paddingBottom: "9%",
        paddingTop: "9%",
      },
    },
    button: {
      paddingLeft: "12%",
      paddingRight: "12%",
      paddingTop: "2%",
      paddingBottom: "2%",
      backgroundColor: "#fc28fc",
      color: "#ffffff",
      borderRadius: "4px",
      border: "none",
      "&:disabled": {
        backgroundColor: "#d3d6de",
        color: "#8b90a7"
      },
    },
    
    select: {
      paddingLeft: "10%",
      paddingRight: "55%",
      "@media (max-width:520px)": {
        paddingRight: "50%",
      },
      "@media (max-width:480px)": {
        paddingRight: "40%",
      },
      "@media (max-width:460px)": {
        paddingRight: "30%",
      },
      "@media (max-width:400px)": {
        paddingRight: "22%",
      },
      paddingTop: "15%",
      paddingBottom: "15%",
      backgroundColor: "#f0f1f4",
      color: "#6e7885",
      borderRadius: "4px",
      borderColor: "#909ea7",
      display: 'flex',
      alignItems: 'left',
      justifyContent: 'center',
    }

});
export default style;
