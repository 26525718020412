import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import TopBarDesktop from "./TopBarDesktop/TopBarDesktop";
import TopBarMobile from "./TopBarMobile/TopBarMobile";
import useNavigation from "../../utils/customHooks/useNavigation";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

const useStyles = makeStyles(styles);

const TopBar = ({ isDesktop, setTopBarHeight }) => {
  const classes = useStyles();

  const topBarRef = useRef(null);
  useEffect(() => {
    setTopBarHeight(topBarRef.current.clientHeight);
  });
  const mobileMenu = useNavigation("mobileMenu");
  const navbarOptions = useNavigation("topBar");
  return (
    <AppBar
      ref={topBarRef}
      className={!isDesktop ? classes.appBarMobile : classes.appBar}
    >
      <Toolbar id="back-to-top-anchor">
        {isDesktop && <TopBarDesktop navigation={navbarOptions} />}
        {!isDesktop && <TopBarMobile navigation={mobileMenu} />}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  isDesktop: PropTypes.bool,
};

export default TopBar;
