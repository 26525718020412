import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import styles from "./styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Circle from "@mui/icons-material/Circle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import MinimizeIcon from "@mui/icons-material/Minimize";
const useStyles = makeStyles(styles);

const AccordionMenuWithoutImage = ({
  title,
  image,
  alt,
  side = true,
  titleVariant,
  textVariant,
  titleColor,
  text,
}) => {
  const classes = useStyles({ titleColor });
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box className={classes.contentBox}>
      {side && (
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title} variant={"h2"}>
              {title}
            </Typography>
            <div>
              {text.map(({ title, content, index }) => (
                <Accordion
                  key={Math.random() * Date.now() + 1}
                  expanded={expanded === `panel_${index}`}
                  onChange={handleChange(`panel_${index}`)}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === `panel_${index}` ? (
                        <MinimizeIcon style={{ color: "white" }} />
                      ) : (
                        <AddIcon style={{ color: "white" }} />
                      )
                    }
                    className={classes.accordionTitle}
                  >
                    <Typography variant={"h4"}>{title}</Typography>
                  </AccordionSummary>
                  {content.map(({ content, subtitle, bullet }) => (
                    <AccordionDetails
                      key={Math.random() * Date.now() + 1}
                      className={classes.accordionText}
                    >
                      <Typography>{content}</Typography>
                      <Typography className={classes.subtitle} variant={"h4"}>
                        {subtitle}
                      </Typography>
                      {bullet && (
                        <ListItem>
                          <Circle fontSize="small" className={classes.icon} />
                          <ListItemText
                            className={classes.bullet}
                            primary={bullet}
                          />
                        </ListItem>
                      )}
                    </AccordionDetails>
                  ))}
                </Accordion>
              ))}
            </div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

AccordionMenuWithoutImage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.any,
  image: PropTypes.string,
  alt: PropTypes.string,
  side: PropTypes.bool,
};

export default AccordionMenuWithoutImage;
