import { lazy } from "react";

export const Contact = lazy(() => import("./Contact"));
export const FreeOrthoConsultation = lazy(() =>
  import("./FreeOrthoConsultation")
);
export const DentistryConsultation = lazy(() =>
  import("./DentistryConsultation")
);

const contact_path = "/contact";
const free_ortho_consultation = "/free_ortho_consultation_form";
const dentistry_consultation_path = "/dentistry_consultation_form";

export const contact = [
  {
    name: "Contact",
    path: `${contact_path}`,
    component: Contact,
  },
  {
    name: "Free Consultation Form",
    path: `${free_ortho_consultation}`,
    component: FreeOrthoConsultation,
  },
  {
    name: "Dentistry Consultation Form",
    path: `${dentistry_consultation_path}`,
    component: DentistryConsultation,
  },
];
