import { theme } from "../../styles/general";
const style = () => ({
  contentImgBox: {
    display: "flex",
    color: "#ff00ff",
    position: "relative",
    flexDirection: "column",
    backgroundColor: "#000000",
    justifyContent: "center",
    "@media (max-width:4000px)": {
      width: "35%",
      padding: "5%",
      bottom: "80%",
    },
    "@media (max-width:1200px)": {
      width: "30%",
      padding: "4%",
      bottom: "60%",
    },
    "@media (max-width:600px)": {
      width: "45%",
      height: "40%",
      padding: "3%",
      bottom: "40%",
    },
    "@media (max-width:400px)": {
      width: "54%",
      height: "40%",
      padding: "2%",
      bottom: "40%",
    },
  },
  consultationButton: {
    display: "flex",
    width: "90%",
    justifyContent: "center",
    color: "#ffffff",
    backgroundColor: "#ff00ff",
    "&:hover": {
      color: "#ff00ff",
      backgroundColor: "#ffffff",
    },
  },
  textContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  title: {
    paddingBottom: "6%",
    "@media (max-width:1000px)": {
      fontSize: "1rem !important",
    },
    "@media (max-width:746px)": {
      fontSize: "0.8rem !important",
    },
    "@media (max-width:572px)": {
      fontSize: "0.65rem !important",
    },
  },
  text: {
    color: "#ffffff",
    paddingBottom: "10%",
    "@media (max-width:1000px)": {
      fontSize: "0.8rem !important",
    },
    "@media (max-width:769px)": {
      fontSize: "0.6rem !important",
    },

    "@media (max-width:294px)": {
      fontSize: "0.4rem !important",
    },
  },
});

export const heroImageStyles = () => ({
  imageBox: {
    width: "80%",
    height: "80vh",
    paddingLeft: "20%",
    backgroundImage:
      "linear-gradient(to top, rgb(248, 248, 248) 60%, rgb(235, 235, 235) 100%)",

    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "right 50% bottom 50%",
      paddingLeft: "0%",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right 50% bottom 50%",
      paddingLeft: "0%",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "right 50% bottom 50%",
      paddingLeft: "0%",
      width: "100%",
    },
  },
  shade: {
    height: "100%",
    width: "100%",
    backgroundImage: ({ background, image }) => `${background}, url(${image})`,
    backgroundRepeat: "no-repeat",
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundSize: "100%",
    backgroundPosition: "right 30% bottom 50%",
    [theme.breakpoints.down("lg")]: {
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right 50% bottom 30%",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundImage: ({ image }) => `url(${image})`,
      backgroundPosition: "right 50% bottom 50%",
    },
    indexZ: -1,
  },
});
export default style;
