import React from "react";
import PropTypes from "prop-types";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FooterBar from "./FooterBar";
import { Link } from "react-router-dom";
import styles, { themeSlogan, themeMobileSubtitles } from "../styles";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(styles);

function FooterDesktop({ list_options }) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.mainBox}>
        <Box className={classes.container}>
          <Box className={classes.socialMediaBox}>
            <a
              href="https://www.facebook.com/SunshineSmilesUS"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon className={classes.imgSocialMedia} />
            </a>
            <a
              href="https://www.instagram.com/sunshinesmilesus/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon className={classes.imgSocialMedia} />
            </a>
          </Box>
          <Grid className={classes.gridParent} container>
            <Grid item xs={6}>
              <ThemeProvider theme={themeSlogan}>
                <Box className={classes.leftTopBoxFooter}>
                  <Typography>
                    {"THE ART & SCIENCE OF A BEAUTIFUL SMILE"}
                  </Typography>
                </Box>
              </ThemeProvider>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                height="100%"
              >
                {list_options.map((item, key) => (
                  <Grid height="100%" item xs={4} key={key}>
                    <Box className={classes.rightTopHeader}>
                      <Typography>{item.name.label}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} height={"10px"}>
              <Box width={"100%"} height={"10px"}>
                <Divider className={classes.divider} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.boxLogoImg}>
                <img
                  src="https://user-images.githubusercontent.com/38444114/152898585-deb9e56b-f304-48cc-9e3e-516a8afce886.svg"
                  alt="Sunshine Smiles Logo"
                  width="400px"
                />
              </Box>
            </Grid>
            <ThemeProvider theme={themeMobileSubtitles}>
              <Grid item xs={6}>
                <Grid container>
                  {list_options.map((option, key) => (
                    <Grid item xs={4} key={key}>
                      {option.options.map((item, key) => (
                        <Link
                          to={item.path ? item.path : "/"}
                          state={window.location.href}
                          key={key}
                        >
                          <Box className={classes.leftBotBoxFooterItem}>
                            <Typography className={classes.subtitles}>
                              {item.label}
                            </Typography>
                          </Box>
                        </Link>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </ThemeProvider>
          </Grid>
        </Box>
      </Box>
      <FooterBar />
    </>
  );
}

FooterDesktop.propTypes = {
  list_options: PropTypes.any,
};

export default FooterDesktop;
