import React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const Item = ({ key, name, description }) => {
  const classes = useStyles();
  return (
    <Box className={classes.itemBox}>
      <Typography variant={"quote"} key={key}>
        {description}
      </Typography>
      <Box className={classes.name}>
        <Typography variant={"pinkQuote"} key={key}>{` - ${name}`}</Typography>
      </Box>
    </Box>
  );
};

Item.propTypes = {
  key: PropTypes.any,
  name: PropTypes.string,
  description: PropTypes.string,
};

export default Item;
