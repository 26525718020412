import React from "react";
import Lottie from "lottie-react";
import { Box } from "@mui/material";
import animationData from "./23917-loading-screen-pink.json";
const LoadingComponent = (props) => {
  return (
    <Box
      style={{
        padding: "100px",
        maxWidth: "50%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Lottie animationData={animationData} />
    </Box>
  );
};

export default LoadingComponent;
